import { InputErrorMessage } from "./InputErrorMessage";
import { classNames } from "../utils/uiUtils";

export const TextArea = ({ label, id, name, value, action, rows, error, disabled }) => {
    return (
        <>
            <label htmlFor={ id } className="block text-sm font-medium leading-6 text-gray-900">
                { label }
            </label>
            <div className="mt-2">
                <textarea
                    id={ id }
                    name={ name }
                    rows={ rows }
                    value={ value }
                    onChange={ action }
                    className={classNames(
                        error
                            ? "ring-red-300 focus:ring-red-600"
                            : "ring-gray-300 focus:ring-mps-ultramarine",
                        "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 whitespace-pre-wrap"
                    )}
                    disabled={ disabled }
                />
                <InputErrorMessage id={ `${id}-error-content` } error={ error } />
            </div>
        </>
    );
};
