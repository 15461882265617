import { useTranslation } from "react-i18next";
import { classNames, formatAsLocalDate, formatTime } from "../utils/uiUtils";
import { InputErrorMessage } from "./InputErrorMessage";
import { DatePickerField } from "./DatePickerField";

export const DateTime = ({ dateFieldId, timeFieldId, label, dateValue, timeValue, dateError, timeError, action, disabled }) => {
    const { t } = useTranslation();

    const getDateValue = () => {
        return formatAsLocalDate(dateValue);
    }

    const getTimeValue = () => {
        return formatTime(timeValue);
    }

    return (
        <>
            <fieldset>
                <legend className="block text-sm font-medium leading-6 text-gray-900">{ label }</legend>
                <div className="mt-2 -space-y-px rounded-md bg-white shadow-sm">
                    <div className="flex -space-x-px">
                        <div className="w-1/2 min-w-0 flex-1">
                            <label htmlFor={ dateFieldId } className="sr-only">
                                { t("Process.Applicants.Fields.Date") }
                            </label>
                            <DatePickerField
                                id={ dateFieldId }
                                name={ dateFieldId }
                                value={ getDateValue() }
                                placeholder={ t("Process.Applicants.Placeholders.Date") }
                                error={dateError}
                                updateAction={ action }
                                comboField={ true }
                                disabled={ disabled }
                            />
                            <InputErrorMessage id={ `${dateFieldId}-error` } error={ dateError } />
                        </div>
                        <div className="min-w-0 flex-1">
                            <label htmlFor={ timeFieldId } className="sr-only">
                                { t("Process.Applicants.Fields.Time") }
                            </label>
                            <input
                                type="text"
                                id={ timeFieldId }
                                name={ timeFieldId }
                                value={ getTimeValue() }
                                onChange={ action }
                                placeholder={ t("Process.Applicants.Placeholders.Time") }
                                autoComplete="off"
                                className={classNames(
                                    timeError
                                        ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
                                        : "text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-mps-ultramarine",
                                    "relative block w-full rounded-none rounded-tr-md rounded-br-md border-0 bg-transparent py-1.5 ring-1 ring-inset focus:z-10 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                                )}
                                disabled={ disabled }
                            />
                            <InputErrorMessage id={ `${timeFieldId}-error` } error={ timeError } />
                        </div>
                    </div>
                </div>
            </fieldset>
        </>
    );
};
