import { useTranslation } from "react-i18next";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import { PencilIcon } from "@heroicons/react/24/solid";
import { formatAsLocalDateTime } from "../utils/uiUtils";

export default function ChangeHistory({ changeHistory }) {
  const { t } = useTranslation();

  const changeDetails = (changedBy, changedAt) => {
    if (changedAt === undefined) {
      return "-";
    }

    return `${changedBy} | ${formatAsLocalDateTime(changedAt)}`;
  };

  return (
    <div className="flex items-center font-medium mt-4 mb-4">
      <InformationCircleIcon
        className="h-5 w-5 flex-shrink-0 text-gray-400"
        aria-hidden="true"
      />
      <span className="text-gray-500 ml-2 text-sm">
        {t("ChangeHistory.Created")}:{" "}
        {changeDetails(changeHistory["createdBy"], changeHistory["createdAt"])}
      </span>
      <PencilIcon
        className="ml-4 h-5 w-5 flex-shrink-0 text-gray-400"
        aria-hidden="true"
      />
      <span className="text-gray-500 ml-2 text-sm">
        {t("ChangeHistory.Modified")}:{" "}
        {changeDetails(
          changeHistory["modifiedBy"],
          changeHistory["modifiedAt"],
        )}
      </span>
    </div>
  );
}
