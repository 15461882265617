import React, { useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { archiveProcess, deleteProcess, searchCustomerByName } from "../api";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { RadioButton } from "../components/RadioButton";
import { TextArea } from "../components/TextArea";
import { useTranslation } from "react-i18next";
import { FormSection } from "./FormSection";
import { EmailGroup } from "../components/EmailGroup";
import { AutoComplete } from "../components/AutoComplete";
import { TextInput } from "../components/TextInput";
import { Required } from "../components/Required";
import { ConfirmDialog } from "../components/ConfirmDialog";
import { getItemPositionByOrdinal } from "./ProcessUtils";
import { isNotBlank } from "../utils/uiUtils";

export function BasicDetailsForm({ formData, errors, fieldUpdateAction, addToEmailGroupAction, updateEmailGroupAction, validateAction, clearErrorsAction, archiveAction }) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [isArchiving, setIsArchiving] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);
    const [removalError, setRemovalError] = useState("");

    const employeeTypes = [
        { name: "consultants", required: true, translationKey: t("Process.Properties.Consultants") },
        { name: "forepersons", required: false, translationKey: t("Process.Properties.Forepersons") },
        { name: "reportRecipients", required: false, translationKey: t("Process.Properties.ReportRecipients") },
        { name: "coordinators", required: false, translationKey: t("Process.Properties.Coordinators") }
    ]

    const archivalInfo = i18n.store.data["fi"]?.translation?.Process?.BasicDetails?.Section4?.InfoList;
    const removalInfo = i18n.store.data["fi"]?.translation?.Process?.BasicDetails?.Section5?.InfoList;

    const handleChange = (e) => {
        const { name, value } = e.target;
        fieldUpdateAction(name, value);
    };

    const updateEmailGroup = (name, value, index, ordinal) => {
        const updatedData = formData[name].map((item) => (item.ordinal === ordinal ? { ...item, email: value } : item));
        updateEmailGroupAction(name, updatedData);
        validateAction( `${name}[${index}].email`, value);
    }

    const addNewFieldToEmailGroup = (e, key) => {
        let existingItems = formData[key];
        const newItem = {
            ordinal: existingItems.length + 1,
            email: '',
        };
        addToEmailGroupAction(key, newItem);
    };

    const removeEmailFromGroup = (e, key, ordinal) => {
        let position = getItemPositionByOrdinal(formData[key], ordinal);
        clearErrorsAction(key, position);
        let updatedItems = formData[key].filter(item => item.ordinal !== ordinal);
        updatedItems.forEach((item, index) => {
            item.ordinal = index + 1;
        });
        updateEmailGroupAction(key, updatedItems);
        updatedItems.forEach((item, index) => {
            validateAction( `${key}[${index}].email`, item.email);
        });
    }

    const handleArchive = async () => {
        setIsArchiving(true);
        const result = await archiveProcess(formData.id);
        if (result.id) {
            archiveAction(true);
            window.scrollTo(0, 0);
        }
        setIsArchiving(false);
    }

    const handleRemoval = async () => {
      setRemovalError("");
      setIsRemoving(true);
      await deleteProcess(formData.id).then(() => {
        const params = [["status", "ProcessRemoved"]];
        navigate({
          pathname: "/",
          search: `?${createSearchParams(params)}`,
        });
      }).catch(() => {
          setRemovalError(t("Process.BasicDetails.Section5.RemoveFailed"));
      }).finally(() => {
        setShowConfirmDialog(false);
        setIsRemoving(false);
      });
    }

    const isDisabled = () => {
        return formData.processState === "Archived";
    }

    const getArchivalInfo = () => {
      return (
        <>
          <div className="col-span-full">
            <h1>{t("Process.BasicDetails.Section4.InfoTitle")}</h1>
          </div>
          <div className="col-span-full">
            <ul className="list-disc pl-4 text-sm">
              {archivalInfo.map((info, index) => (
                <li key={index}>{info}</li>
              ))}
            </ul>
          </div>
        </>
      );
    };

    return (
            <AuthenticatedTemplate>
                <FormSection title={t("Process.BasicDetails.Section1.Title")} summary={t("Process.BasicDetails.Section1.Summary")}>
                    <div className="grid max-w-3xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="sm:col-span-3">
                            <AutoComplete
                                label={ t("Process.Properties.Customer") }
                                id="customer.name"
                                name="customer.name"
                                placeholder={ t("Process.BasicDetails.Section1.Placeholders.Customer") }
                                value={ formData.customer.name }
                                action={ fieldUpdateAction }
                                searchAction={ searchCustomerByName }
                                error={ errors["customer.name"] }
                                required={ true }
                                disabled={ isDisabled() }
                            />
                        </div>

                        <div className="sm:col-span-3">
                            <TextInput
                                label={t("Process.Properties.JobTitle")}
                                id="jobTitle"
                                name="jobTitle"
                                placeholder={t("Process.BasicDetails.Section1.Placeholders.JobTitle")}
                                value={ formData.jobTitle }
                                action={ handleChange }
                                required={ true }
                                error={ errors["jobTitle"] }
                                disabled={ isDisabled() }
                            />
                        </div>

                        <div className="col-span-full">
                            <fieldset className="w-full">
                                <legend className="text-sm font-semibold leading-6 text-gray-900">{t("Process.Properties.AssessmentLevel")}<Required/></legend>
                                <div className="mt-6 flex flex-row flex-wrap columns-2xl">
                                    <RadioButton
                                        label= {t("Process.Properties.Manager")}
                                        id="assessmentLevel.Manager"
                                        name="assessmentLevel"
                                        value="Manager"
                                        action={ handleChange }
                                        checked={ formData.assessmentLevel === 'Manager' }
                                        disabled={ isDisabled() }
                                    />
                                    <RadioButton
                                        label= {t("Process.Properties.Expert")}
                                        id="assessmentLevel.Expert"
                                        name="assessmentLevel"
                                        value="Expert"
                                        action={ handleChange }
                                        checked={ formData.assessmentLevel === 'Expert' }
                                        disabled={ isDisabled() }
                                    />
                                    <RadioButton
                                        label= {t("Process.Properties.Foreperson")}
                                        id="assessmentLevel.Foreperson"
                                        name="assessmentLevel"
                                        value="Foreperson"
                                        action={ handleChange }
                                        checked={ formData.assessmentLevel === 'Foreperson' }
                                        disabled={ isDisabled() }
                                    />
                                    <RadioButton
                                        label= {t("Process.Properties.Official")}
                                        id="assessmentLevel.Official"
                                        name="assessmentLevel"
                                        value="Official"
                                        action={ handleChange }
                                        checked={ formData.assessmentLevel === 'Official' }
                                        disabled={ isDisabled() }
                                    />
                                    { errors.assessmentLevel && (
                                        <p className="mt-2 text-sm text-red-600" id="email-error">{errors.assessmentLevel}</p>
                                    )}
                                </div>
                            </fieldset>
                        </div>

                        <div className="col-span-full">
                            <fieldset className="w-full">
                                <legend className="text-sm font-semibold leading-6 text-gray-900">{t("Process.Properties.ServiceType")}</legend>
                                <div className="mt-6 flex flex-row flex-wrap columns-2xl">
                                    <RadioButton
                                        label= {t("Process.Properties.PersonalAssessment")}
                                        id="serviceType.PersonalAssessment"
                                        name="serviceType"
                                        value="PersonalAssessment"
                                        action={ handleChange }
                                        checked={ formData.serviceType === 'PersonalAssessment' }
                                        disabled={ isDisabled() }
                                    />
                                    <RadioButton
                                        label= {t("Process.Properties.NotificationSearch")}
                                        id="serviceType.NotificationSearch"
                                        name="serviceType"
                                        value="NotificationSearch"
                                        action={ handleChange }
                                        checked={ formData.serviceType === 'NotificationSearch' }
                                        disabled={ isDisabled() }
                                    />
                                    <RadioButton
                                        label= {t("Process.Properties.DirectSearch")}
                                        id="serviceType.DirectSearch"
                                        name="serviceType"
                                        value="DirectSearch"
                                        action={ handleChange }
                                        checked={ formData.serviceType === 'DirectSearch' }
                                        disabled={ isDisabled() }
                                    />
                                    <RadioButton
                                        label= {t("Process.Properties.OPC")}
                                        id="serviceType.Opc"
                                        name="serviceType"
                                        value="Opc"
                                        action={ handleChange }
                                        checked={ formData.serviceType === 'Opc' }
                                        disabled={ isDisabled() }
                                    />
                                    <RadioButton
                                        label= {t("Process.Properties.CombinationSearch")}
                                        id="serviceType.CombinationSearch"
                                        name="serviceType"
                                        value="CombinationSearch"
                                        action={ handleChange }
                                        checked={ formData.serviceType === 'CombinationSearch' }
                                        disabled={ isDisabled() }
                                    />
                                    <RadioButton
                                        label= {t("Process.Properties.OtherService")}
                                        id="serviceType.OtherService"
                                        name="serviceType"
                                        value="OtherService"
                                        action={ handleChange }
                                        checked={ formData.serviceType === 'OtherService' }
                                        disabled={ isDisabled() }
                                    />
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </FormSection>

                <FormSection title={t("Process.BasicDetails.Section2.Title")} summary={t("Process.BasicDetails.Section2.Summary")}>
                    <div className="grid max-w-3xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        <div className="col-span-full">
                            <TextArea
                                label= {t("Process.Properties.Schedule")}
                                id="schedule"
                                name="schedule"
                                value={ formData.schedule }
                                action={ handleChange }
                                rows={3}
                                error={ errors["schedule"] }
                                disabled={ isDisabled() }
                            />
                        </div>
                    </div>
                </FormSection>
                <FormSection title={t("Process.BasicDetails.Section3.Title")} summary={t("Process.BasicDetails.Section3.Summary")}>
                    <div className="grid max-w-3xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                        { employeeTypes.map((employeeType) => (
                            <div className="sm:col-span-full" key={ employeeType.name }>
                                <EmailGroup
                                    label={ employeeType.translationKey }
                                    id={ employeeType.name }
                                    name={ employeeType.name }
                                    placeholder={t("Process.BasicDetails.Section3.Placeholders.Email")}
                                    items={ formData[employeeType.name] }
                                    required={ employeeType.required }
                                    action={ updateEmailGroup }
                                    errors={ errors }
                                    addAction={ (event) => addNewFieldToEmailGroup(event, employeeType.name)}
                                    removeAction={ removeEmailFromGroup }
                                    disabled={ isDisabled() }
                                />
                            </div>
                        ))}
                    </div>
                </FormSection>

                {formData.processState === "Published" && (
                    <FormSection title={t("Process.BasicDetails.Section4.Title")} summary={t("Process.BasicDetails.Section4.Summary")}>
                        <div className="grid max-w-3xl grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 md:col-span-2">
                            {getArchivalInfo()}
                            <button onClick={() => setShowConfirmDialog(true)} className="col-span-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" type="button">
                                {t("Process.BasicDetails.Section4.ArchiveButton")}
                            </button>
                            <ConfirmDialog
                                title={t("Process.BasicDetails.Section4.DialogTitle")}
                                jsx={getArchivalInfo()}
                                confirmButtonLabel={t("Process.BasicDetails.Section4.ArchiveButton")}
                                action={handleArchive}
                                open={showConfirmDialog}
                                setOpen={setShowConfirmDialog}
                                ongoingAction={isArchiving}
                            />
                        </div>
                    </FormSection>
                )}

                {formData.processState === "Draft" && (
                    <FormSection title={t("Process.BasicDetails.Section5.Title")} summary={t("Process.BasicDetails.Section5.Summary")}>
                        <div className="grid max-w-3xl grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 md:col-span-2">
                            <div className="col-span-full">
                                <h1>{t("Process.BasicDetails.Section5.InfoTitle")}</h1>
                            </div>
                            <div className="col-span-full">
                                <ul className="list-disc pl-4 text-sm">
                                    {removalInfo.map((info, index) => (
                                        <li key={index}>{info}</li>
                                    ))}
                                </ul>
                            </div>
                            { isNotBlank(removalError) && (
                                <div className="col-span-full text-sm text-red-600">{removalError}</div>
                            )}
                            <button onClick={() => setShowConfirmDialog(true)} className="col-span-2 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" type="button">
                                {t("Process.BasicDetails.Section5.RemoveButton")}
                            </button>
                            <ConfirmDialog
                                title={t("Process.BasicDetails.Section5.DialogTitle")}
                                confirmButtonLabel={t("Process.BasicDetails.Section5.RemoveButton")}
                                content={t("Process.BasicDetails.Section5.ConfirmRemoval")}
                                action={handleRemoval}
                                open={showConfirmDialog}
                                setOpen={setShowConfirmDialog}
                                ongoingAction={isRemoving}
                            />
                        </div>
                    </FormSection>
                )}

            </AuthenticatedTemplate>
    );
}
