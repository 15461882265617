import { LoadingIndicator } from "./LoadingIndicator";
import { useTranslation } from "react-i18next";
import { isNotBlank } from "../utils/uiUtils";

export const Overlay = ({ isActive, message }) => {
  const { t } = useTranslation();
  return (
    <>
      {isActive && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-700 bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-md shadow-lg">
            <div className="flex items-center space-x-4">
              <LoadingIndicator />
              {isNotBlank(message) ? (
                <div>{message}</div>
              ) : (
                <div>{t("Overlay.Loading")}</div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
