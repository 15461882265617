import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import { PageHeading } from "../components/PageHeading";
import { ProcessList } from "../process/ProcessList";
import { useUserContext } from "../UserContextProvider";
import { useSearchParams } from "react-router-dom";
import { Notification } from "../components/Notification";
import { useState } from "react";

export function Home() {
  const { t } = useTranslation();
  const { instance } = useMsal();
  const { hasTalentHubRole } = useUserContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const hasStatus = searchParams.has('status');
  const [showNotification, setShowNotification] = useState(hasStatus);

  const account = instance.getActiveAccount();

  const getNotificationTitle = () => {
    if (hasStatus) {
      let statusKey = searchParams.get('status');
      return t(`Notifications.Status.${statusKey}`, statusKey);
    }
    return "";
  };

  const closeNotification = () => {
    setShowNotification(false);
    setSearchParams({});
  };

  return (
    <>
      <AuthenticatedTemplate>
        <PageHeading text={t("HomePage.Title", { username: account.name })} />
        <div className="flex flex-col">
          {hasTalentHubRole ? (
            <>
              <ProcessList state={"Draft"} />
              <ProcessList state={"Published"} />
            </>
          ) : null}
        </div>
        {/* Global notification live region */}
        <div
            aria-live="assertive"
            className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6"
        >
          <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
            <Notification
                title={getNotificationTitle()}
                content={""}
                show={showNotification}
                action={closeNotification}
                isSuccess={hasStatus}
            />
          </div>
        </div>
      </AuthenticatedTemplate>
    </>
  );
}
