import { msalConfig } from '../msal/MsalConfig';
import { editProcess, postProcess } from '../api';

/**
 * Stores the claim challenge to the sessionStorage in the browser to be used when acquiring a token
 * @param {String} claimsChallenge
 * @param {String} claimsChallengeId
 */
export const addClaimsToStorage = (claimsChallengeId, claimsChallenge) => {
    sessionStorage.setItem(claimsChallengeId, claimsChallenge);
};

/**
 * Retrieves the claim challenge from the sessionStorage in the browser
 * @param {String} claimsChallengeId
 */
export const getClaimsFromStorage = (claimsChallengeId) => {
    return sessionStorage.getItem(claimsChallengeId);
};

/**
 * Clears sessionStorage of any claims challenge entry
 * @param {Object} account
 */
export const clearStorage = (account) => {
    for (let key in sessionStorage) {
        if (key.startsWith(`cc.${msalConfig.auth.clientId}.${account.idTokenClaims.oid}`)) sessionStorage.removeItem(key);
    }
};

/**
 * This method calls the API if the a access token is fetched
 */
export const callEndpoint = (options, id) => {
    switch (options["method"]) {
        case "POST":
            let newProcess = JSON.parse(options.body);
            return postProcess(newProcess)
                .then(res => res);
        case "PUT":
            let process = JSON.parse(options.body);
            return editProcess(id, process)
                .then(res => res)
        default:
            break;
    }
}
