import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { getCompletedCandidateProcessAssessments } from "../../api";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { dateTimeToLocalDate, isEmptyArray } from "../../utils/uiUtils";
import { parenthesizeIfNotEmpty } from "../../process/ProcessUtils";

export const CandidateProgress = ({ candidateId }) => {
  const { t } = useTranslation();

  const [assessmentsLoading, setAssessmentsLoading] = useState(true);
  const [assessments, setAssessments] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      await getCompletedCandidateProcessAssessments(candidateId)
        .then((response) => {
          setAssessments(response);
        })
        .catch((error) => {
          console.log(error);
          setAssessments([]);
        })
        .finally(() => {
          setAssessmentsLoading(false);
        });
    };
    if (assessmentsLoading) {
      fetchData().catch(console.error);
    }
  }, [candidateId, assessments, assessmentsLoading]);

  return (
    <div className="col-span-1 gap-x-8 gap-y-10 border-b border-gray-900/10 bg-white shadow-sm ring-2 ring-gray-900/5 sm:rounded-xl w-full">
      <div className="w-full text-base flex items-center justify-between pl-4 pr-4 pt-4">
        <div className="container mx-auto w-full divide-y">
          <div className="flex mb-4 gap-x-2 text-sm text-gray-700">
            {assessmentsLoading ? (
              <LoadingIndicator type="small" />
            ) : isEmptyArray(assessments) ? (
              <div>{t("Candidate.TestResults.NoTestResults")}</div>
            ) : (
              <div>
                <h3  className="text-base font-bold leading-7 text-gray-900 sm:truncate sm:text-base sm:tracking-tight">{t("Candidate.TestResults.Assessments")}</h3>
                <ul className="list-disc list-inside text-gray-700 mt-2 pl-2">
                {assessments.map((assessment) => (
                  <li
                    key={`applicant_assessment_key_${assessment.id}`}
                    className="list-item justify-between py-1 pl-1 pr-1 text-sm"
                  >
                    {assessment.provider}: {assessment.name} {parenthesizeIfNotEmpty(assessment.options)} ({dateTimeToLocalDate(assessment.completedAt)})
                  </li>
                ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
