import { useTranslation } from "react-i18next";
import { Interview } from "./Interview";
import { AssessmentFilters } from "./AssessmentFilters";
import { assessmentStatusColor } from "../../process/ProcessUtils";
import { LoadingIndicator } from "../../components/LoadingIndicator";
import { useEffect, useState } from "react";
import { downloadAssessmentLog, searchApplicants } from "../../api";
import { getTomorrowsDateAsISO, isEmptyArray } from "../../utils/uiUtils";
import { Tooltip } from "../../components/Tooltip";
import { Pagination } from "./Pagination";
import { Link } from "react-router-dom";

export function AssessmentLog() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [applicantProcesses, setApplicantProcesses] = useState([]);
  const [pages, setPages] = useState(0);

  const searchParameters = Object.freeze({
    fromDate: getTomorrowsDateAsISO(),
    toDate: getTomorrowsDateAsISO(),
    offices: [],
    interviews: true,
    groupSimulations: true,
    individualSimulations: true,
    page: 0,
    pageSize: 25
  });

  const [searchData, setSearchData] = useState(searchParameters);

  const applyFilterChange = (name, value) => {
    setSearchData({ ...searchData, [name]: value });
  };

  const updateResults = (e) => {
    e.preventDefault();
    setApplicantProcesses([]);
    setIsLoading(true);
  };

  const downloadResults = async (e) => {
    e.preventDefault();
    await downloadAssessmentLog(searchData);
  };

  const hasMultipleConsultants = (consultants) => {
    return !isEmptyArray(consultants) && consultants.length > 1;
  };

  const consultantNames = (consultants) => {
    return consultants.map((person) =>
      person.name.trim() ? person.name : person.email,
    );
  };

  const showNextPage = (e) => {
    if (searchData.page + 1 >= pages) {
      return;
    }
    applyFilterChange("page", searchData.page + 1);
    updateResults(e);
  }

  const showPrevPage = (e) => {
    if (searchData.page - 1 < 0) {
      return;
    }
    applyFilterChange("page", searchData.page - 1);
    updateResults(e);
  }

  const consultantEmail = (consultant) => {
    // consultant.name contains email if first name and last name are not available
    return consultant.name === consultant.email ? "" : consultant.email
  }

  useEffect(() => {
    const fetchData = async () => {
      await searchApplicants(searchData)
        .then((response) => {
          setApplicantProcesses(response.entries);
          setPages(response.pages);
        })
        .catch(() => {
          setApplicantProcesses([]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    if (isLoading) {
      fetchData().catch(console.error);
    }
  }, [applicantProcesses, isLoading, searchData]);

  return (
    <div className="md:flex md:items-center md:justify-between">
      <div className="w-full">
        <AssessmentFilters
          data={searchData}
          updateFiltersAction={applyFilterChange}
          applyFiltersAction={updateResults}
          downLoadAction={downloadResults}
        />

        <div className="mt-8 mb-1">
          <Pagination page={searchData.page} totalPages={pages} showNextPage={showNextPage} showPrevPage={showPrevPage} />
        </div>
        <div className="flow-root border-b border-gray-900/10 pb-12 bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-l sm:rounded-r p-6">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                    >
                      {t("Search.AssessmentLog.Applicant")}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {t("Search.AssessmentLog.Interview")}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {t("Search.AssessmentLog.GroupSimulation")}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {t("Search.AssessmentLog.IndividualSimulation")}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {t("Search.AssessmentLog.Consultant")}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      {t("Search.AssessmentLog.Assessments")}
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {isLoading && (
                    <tr aria-colspan={6}>
                      <td colSpan={6} className="pt-10">
                        <LoadingIndicator type="small" />{" "}
                        <span className="pl-3">{t("Overlay.Loading")}</span>
                      </td>
                    </tr>
                  )}
                  {!isLoading && isEmptyArray(applicantProcesses) && (
                      <tr aria-colspan={6}>
                        <td colSpan={6} className="pt-10">
                          <span className="text-sm italic text-gray-900">{t("Search.AssessmentLog.NoResults")}</span>
                        </td>
                      </tr>
                  )}
                  {applicantProcesses?.map((item) => (
                    <tr key={`${item.email}_${item.processId}`}>
                      <td className="whitespace-nowrap py-5 pr-3 text-sm sm:pl-0 align-top">
                        <div className="flex items-center">
                          <div className="">
                            <div className="font-medium text-gray-900">
                              <Link
                                  to={`/candidates/${item.candidateId}`}
                                  className="font-medium text-mps-ultramarine pr-4 dark:text-mps-ultramarine hover:underline"
                              >
                                {item.lastName} {item.firstName}
                              </Link>
                            </div>
                            <div className="mt-1 text-gray-500">
                              {item.email} | {item.phoneNumber}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 align-top">
                        <Interview
                          interview={item.individualInterview}
                          office={item.office}
                        />
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 align-top">
                        <Interview
                          interview={item.groupSimulation}
                          office={item.office}
                        />
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 align-top">
                        <Interview
                          interview={item.individualSimulation}
                          office={item.office}
                        />
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 align-top">
                        {(() => {
                          if (hasMultipleConsultants(item.consultants)) {
                            return (
                              <Tooltip
                                messages={consultantNames(item.consultants)}
                              >
                                {t("Generic.Multiple")}
                              </Tooltip>
                            );
                          } else if (item.consultants) {
                            const consultantName = item.consultants.at(0).name;
                            return (
                              <div className="font-medium text-gray-900">
                                {consultantName}
                                <br />
                                {consultantEmail(item.consultants.at(0))}
                              </div>
                            );
                          } else {
                            return (
                              <div className="font-medium text-gray-900">-</div>
                            );
                          }
                        })()}
                      </td>
                      <td className="px-3 py-5 text-sm text-gray-500 align-top">
                        {item.assessments.map((assessment) => (
                          <div
                            key={assessment.name}
                            className={assessmentStatusColor(assessment.status)}
                          >
                            {assessment.name}
                          </div>
                        ))}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="mt-1">
          <Pagination page={searchData.page} totalPages={pages} showNextPage={showNextPage} showPrevPage={showPrevPage} />
        </div>
      </div>
    </div>
  );
}
