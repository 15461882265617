import { useState } from "react";
import { useTranslation } from "react-i18next";
import { InputErrorMessage } from "./InputErrorMessage";
import { CancelIcon, ConfirmIcon, EditIcon } from "./Icons";
import { classNames, highlightByFilter } from "../utils/uiUtils";

export const CriteriaCard = ({ id, value, title, content, isChecked, action, onEditConfirmedAction, error, filterTerm, position, isModified = false, disabled = false }) => {
    const { t } = useTranslation();

    const [editMode, setEditMode] = useState(false);
    const [currentContent, setCurrentContent] = useState(content.slice());

    const onEditClicked = (e) => {
        e.preventDefault();
        setEditMode(true);
    };

    const onEdit = (e) => {
        e.preventDefault();
        const { value } = e.target;
        setCurrentContent(value);
    }

    const onEditCancelled = (e) => {
        e.preventDefault();
        setCurrentContent(content.slice());
        closeEditMode();
    }
    const onEditConfirmed = (e) => {
        e.preventDefault();
        if (hasUnsavedChanges()) {
            onEditConfirmedAction(value, currentContent);
        }
        closeEditMode();
    }

    const hasUnsavedChanges = () => {
        return currentContent !== content;
    }

    const showModifiedText = () => {
        return isModified || hasUnsavedChanges();
    }

    const closeEditMode = () => {
        setEditMode(false);
    }

    const onCardSelect = (e, id) => {
        if (hasUnsavedChanges()) {
            onEditConfirmedAction(id, currentContent);
        } else {
            action(e, id);
        }
    }

    const highlightMatchingText = (text) => {
        return highlightByFilter(text, filterTerm);
    };

    const categorySelectedClasses = () => {
        switch(position) {
            case 1:
                return 'peer-checked:border-mps-purple'
            case 2:
                return 'peer-checked:border-mps-honey'
            case 3:
                return 'peer-checked:border-mps-ruby'
            default:
                return 'peer-checked:border-mps-ultramarine'
        }
    }

    return (
        <div className="flex flex-wrap">
            <input type="checkbox"
                   id={ id }
                   value={ value }
                   checked={ isChecked }
                   onChange={(event) => onCardSelect(event, value)}
                   className="hidden peer"
                   disabled={ disabled } />
            <label htmlFor={ id }
                   className={classNames(
                       categorySelectedClasses(),
                       "top-0 inline-flex items-center justify-between w-full p-5 text-gray-900 bg-white border-2 border-gray-200 rounded-lg cursor-pointer peer-checked:border-3",
                       "dark:hover:text-gray-300 dark:border-gray-700 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-900 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700",
                       "shadow drop-shadow-sm"
                   )}>
                <div className="block w-full">
                    <div className="w-full text-base font-semibold flex items-center justify-between">
                        <div className="text-left">
                            <span className="text-lg font-semibold">{ highlightMatchingText(title) }</span>
                            { showModifiedText() && (
                                <span className="text-sm text-green-700 pl-2">({ t("ChangeHistory.Modified") })</span>
                            )}
                        </div>
                        { editMode &&  (
                            <div className="flex items-center space-x-4">
                                <CancelIcon action={ (event) => onEditCancelled(event) } />
                                <ConfirmIcon action={ (event) => onEditConfirmed(event) } />
                            </div>
                        )}
                        { !editMode &&  (
                            <div className="flex items-center space-x-4">
                                <EditIcon action={ (event) => onEditClicked(event) } />
                            </div>
                        )}
                    </div>
                    { editMode &&  (
                        <div className="w-full text-sm pt-4">
                            <textarea
                                id={ `criteria.${id}` }
                                name={ `criteria.${id}` }
                                value={ currentContent }
                                onChange={ (event) => onEdit(event) }
                                rows={3}
                                className={classNames(
                                    error
                                        ? "focus:ring-red-600"
                                        : "focus:ring-blue-600",
                                    "w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                                )}
                                disabled={ disabled }
                            />
                        </div>
                    )}
                    { !editMode &&  (
                        <div className="w-full text-sm pt-4">
                            { highlightMatchingText(currentContent) }
                        </div>
                    )}
                    { error && <InputErrorMessage id={ `criteria.${id}-error` } error={ error } /> }
                </div>
            </label>
        </div>
    );
};
