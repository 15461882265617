import { InputErrorMessage } from "./InputErrorMessage";
import { Required } from "./Required";
import { LoadingIndicator } from "./LoadingIndicator";
import { TrashIcon } from "@heroicons/react/24/solid";
import { classNames } from "../utils/uiUtils";
import { textInputClasses, textInputCommonClasses, textInputErrorClasses } from "./ComponentStyles";
import { ExclamationCircleIcon } from "@heroicons/react/20/solid";

export const TextInput = ({ label, id, name, placeholder, value, action, keyDownAction, error, additionalIcon, additionalIconAction, loading = false, suppressErrors = false, required = false, stretch = true, disabled = false }) => {

    const onKeyDownHandler = (event) => {
        if (keyDownAction) {
            keyDownAction(event);
        }
    }

    return (
        <>
            { label && (
                <label htmlFor={ id } className="block text-sm font-medium leading-6 text-gray-900">
                    { label }{ required && required === true && <Required/> }
                </label>
            )}
            <div className="relative mt-2">
                <div className="flex items-center">
                    <input
                        type="text"
                        id={ id }
                        name={ name }
                        placeholder={ placeholder }
                        value={ value }
                        onChange={ action }
                        onKeyDown={ onKeyDownHandler }
                        className={classNames(
                            !suppressErrors && error
                                ? textInputErrorClasses
                                : textInputClasses,
                            stretch ? 'w-full' : 'w-11/12',
                            textInputCommonClasses
                        )}
                        disabled={ disabled }
                    />
                    { additionalIcon && additionalIcon === 'TrashIcon' &&
                        <span className="w-1/12 pl-2">
                            <TrashIcon className="-ml-0.5 h-5 w-5 hover:cursor-pointer" aria-hidden="true" onClick={ additionalIconAction } />
                        </span>
                    }
                </div>
                { !suppressErrors && error &&
                    <div className={
                        classNames(
                            stretch ? 'right-0' : 'right-10',
                            "pointer-events-none absolute inset-y-0 flex items-center pr-3"
                        )}>
                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                    </div>
                }
                { loading && (
                    <div className={
                             classNames(
                                 stretch ? 'right-0' : 'right-10',
                                 "absolute inset-y-0 flex items-center pr-3 text-gray-400"
                             )}>
                        <LoadingIndicator type="small" />
                    </div>
                )}
            </div>
            { !suppressErrors && <InputErrorMessage id={ `${id}-error` } error={ error } /> }
        </>
    );
};
