import { TextArea } from "./TextArea";
import { getFieldError } from "../process/ProcessUtils";

export const ApplicantNotes = ({ fieldPrefix, label, value, handleChange, errors, disabled }) => {
    const getError = (fieldSuffix) => {
        return getFieldError(errors, fieldPrefix, fieldSuffix);
    }

    return (
        <>
            <div className="w-full p-4 mb-4">
                <TextArea
                    label={ label }
                    id={ `${fieldPrefix}.notes` }
                    name={ `${fieldPrefix}.notes` }
                    value={ value }
                    action={ handleChange }
                    rows={ 8 }
                    error={ getError("notes") }
                    disabled={ disabled }
                />
            </div>
        </>
    );
};
