import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { RadioButton } from "../components/RadioButton";
import { InterviewType } from "../components/InterviewType";
import { Timeframe } from "./components/Timeframe";
import { getFieldError } from "./ProcessUtils";

export const IndividualInterviewCard = ({ position, item, updateAction, errors, disabled }) => {
    const { t } = useTranslation();
    const individualInterviewDurationOptions = [30,45,60,75,90,120];
    const fieldPrefix = `individualInterviews[${position}]`;

    const handleChange = (event, fieldSuffix) => {
        updateAction(item.ordinal, fieldSuffix, event.target.value);
    }

    const getError = (fieldSuffix) => {
        return getFieldError(errors, fieldPrefix, fieldSuffix);
    }

    return (
        <>
            <div className="col-span-full">
                <InterviewType
                    label={ t("Process.Properties.IndividualInterviewType") }
                    item={ item }
                    fieldPrefix={ fieldPrefix }
                    updateAction={ (event) => handleChange(event,"interviewType") }
                    required={ true }
                    error={ getError("interviewType") }
                    includeNoInterview={ true }
                    noInterviewLabel={ t("Process.Properties.IndividualInterviewNone") }
                    disabled={ disabled }
                />
            </div>

            <div className="col-span-full">
                <fieldset className="w-full">
                    <legend className="text-sm font-semibold leading-6 text-gray-900">{t("Process.Properties.IndividualInterviewDuration")}</legend>
                    <div className="mt-2 flex flex-row flex-wrap columns-2xl">
                        { individualInterviewDurationOptions.map((minutes) => (
                            <Fragment key={ `rb_key_iid.${minutes}` }>
                                <RadioButton
                                    label= {t("Process.Properties.Minutes", { minutes: minutes })}
                                    id={ `${fieldPrefix}.duration.teams.${minutes}` }
                                    name={ `${fieldPrefix}.duration` }
                                    value={ minutes }
                                    action={ (event) => handleChange(event,"duration") }
                                    checked={ `${item.duration}` === `${minutes}` }
                                    disabled={ disabled }
                                />
                            </Fragment>
                        ))}
                    </div>
                </fieldset>
            </div>

            <div className="col-span-full">
                <fieldset className="w-full">
                    <Timeframe
                        title={t("Process.Properties.Timeframe")}
                        fieldPrefix={fieldPrefix}
                        updateAction={handleChange}
                        startDate={item.timeframeStart}
                        endDate={item.timeframeEnd}
                        startError={getError("timeframeStart")}
                        endError={getError("timeframeEnd")}
                        required={true}
                        disabled={ disabled }
                    />
                </fieldset>
            </div>
        </>
    );
};
