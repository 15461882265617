import React, { useEffect, useState } from "react";
import { SelectBox } from "../../components/SelectBox";
import { classNames, isBlank, isNotBlank } from "../../utils/uiUtils";
import { useTranslation } from "react-i18next";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

export const AssessmentLanguageSelection = ({
  providerId,
  assessment,
  updateAction,
  formData,
  applicant,
  disabled
}) => {
  const { t } = useTranslation();

  const currentAssessment = formData.find(
    (item) =>
      item.providerId === providerId && item.assessmentId === assessment.id
  );

  const isActive = currentAssessment?.value && applicant.candidateId;

  const nothingSelectedOption = {
    key: "",
    value: t("Process.Actions.SelectLanguage"),
  };
  const availableLanguages = [nothingSelectedOption].concat(
    assessment.languages.values ?? []
  );

  const defaultLang = availableLanguages.find(r => r.key === applicant.languageCode);

  const existingCandidateData = currentAssessment?.assessmentData.find(
    (item) => item.candidateId === applicant.candidateId
  );

  useEffect(() => {
    if (isActive) {
      if (isBlank(existingCandidateData?.language) && defaultLang?.key === applicant.languageCode) {
        updateLanguage(applicant.languageCode);
      }
    }
  });

  const getInitialLanguage = () => {
    if (isNotBlank(existingCandidateData?.language)) {
      return existingCandidateData?.language;
    } else if (defaultLang) {
      return defaultLang.key;
    }
    return "";
  }

  const [language, setLanguage] = useState(
      getInitialLanguage()
  );

  const handleChange = (event) => {
    if (event.target.value === language) {
      return;
    }

    updateLanguage(event.target.value);
  };

  const updateLanguage = (lang) => {
    setLanguage(lang);
    updateAction(
      { candidateId: applicant.candidateId, language: lang },
      providerId,
      assessment.id,
    );
  };

  const selectedLanguage = () => {
    if (isNotBlank(language)) {
      const optionIndex = availableLanguages.findIndex(
        (entry) => entry.key === language,
      );
      return optionIndex > 0 ? optionIndex : 0;
    }

    return 0;
  };

  const inputLabel = `${applicant?.firstName} ${applicant?.lastName}`;

  const id = `personalLanguage_${providerId}_${assessment.id}_${applicant.candidateId}`;

  const selectedOption = selectedLanguage();

  return (
    <>
      {isActive ? (
        <div className="flex items-center flex-wrap w-full">
          <div className="flex text-sm w-3/5">{inputLabel}</div>
          <div className="flex text-sm justify-end w-1/5">
            {selectedOption === 0 && (
              <div className="mt-4 mr-1">
                <ExclamationTriangleIcon
                  aria-hidden="true"
                  className={classNames("ml-4 h-5 w-5 text-red-700")}
                />
              </div>
            )}
          </div>
          <div className="flex text-sm justify-end ml-auto w-1/5">
            <SelectBox
              id={id}
              options={availableLanguages}
              required={false}
              action={handleChange}
              disabled={disabled}
              selected={selectedOption}
              idProperty="key"
              nameProperty="value"
            />
          </div>
        </div>
      ) : null}
    </>
  );
};
