import { ToggleOnLeft } from "../../components/Toggle";
import { isEmptyArray, isEmptyJson } from "../../utils/uiUtils";
import { useTranslation } from "react-i18next";
import {
  getProviderNameByAssessmentId,
  sortVerifyOptions,
} from "../ProcessUtils";

export const ApplicantAssessmentsToggleCard = ({
  applicant,
  selectedAssessments,
  testProviders,
  disableApplicantAssessmentAction,
  disabledApplicantAssessments,
  disabled
}) => {
  const { t } = useTranslation();

  const handleToggle = (assessmentId, value) => {
    disableApplicantAssessmentAction(applicant.candidateId, assessmentId, value);
  };

  const getSelectedOptions = (item) => {
    if (isEmptyArray(item.options)) {
      return "";
    }

    if (item.name === "Verify") {
      return sortVerifyOptions(item.options);
    }

    return item.options.map((option) => {
      return `${t(`Process.TestsAndMethods.Assessments.Options.${option}`, option)}`;
    }).join(",");
  };

  const getAssessmentLabel = (item) => {
    let providerName = getProviderNameByAssessmentId(testProviders, item.assessmentId);
    let optionNames = getSelectedOptions(item);
    return `${providerName}${item.name} ${optionNames}`;
  };

  const isDisabled = (id) => {
    return disabledApplicantAssessments.some(item => item.assessmentId === id);
  }

  return (
    <>
      <div
        key={`applicant_${applicant.id}_key`}
        className="w-full py-3 pl-2 bg-mps-light-gray border-t border-b border-gray-300"
      >
        {applicant.firstName} {applicant.lastName}
      </div>
      <div>
        {isEmptyJson(selectedAssessments) ? (
          <div className="mt-3 text-sm">
            {t("Process.TestsAndMethods.Section1.Notification.NoneSelected")}
          </div>
        ) : (
          selectedAssessments.map((item, index) => (
            <div
              key={`selected_assessments_toggle_${index}_key`}
              className="border-b border-gray-300 mt-3"
            >
              <ToggleOnLeft
                label={getAssessmentLabel(item)}
                value={!isDisabled(item.assessmentId)}
                action={(value) => {
                  handleToggle(item.assessmentId, value);
                }}
                disabled={disabled}
              />
            </div>
          ))
        )}
      </div>
    </>
  );
};
