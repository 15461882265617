import { classNames } from "../utils/uiUtils";

export const LoadingIndicator = ({ type = "regular" }) => {
    const smallClasses = "inline-block h-4 w-4 animate-spin rounded-full border-2 border-mps-ultramarine border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]";
    const regularClasses = "inline-block h-8 w-8 animate-spin rounded-full border-4 border-mps-ultramarine border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]";

    return(
        <>
            <div
                role="status"
                className={classNames(
                    type === "small"
                        ? smallClasses
                        : regularClasses
                )}>
                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Loading...</span>
            </div>
        </>
    );
};
