import unionWith from "lodash.unionwith";
import unionBy from "lodash.unionby";
import cloneDeep from "lodash.clone";
import { useTranslation } from "react-i18next";
import { LoadingIndicator } from "../components/LoadingIndicator";
import { AssessmentSummary } from "../components/AssessmentSummary";
import { Assessment } from "../components/Assessment";
import { TextArea } from "../components/TextArea";
import { PersonalLink } from "./PersonalLink";
import { SharedLink } from "./SharedLink";
import { Fragment, useEffect, useState } from "react";
import { getItemPositionByOrdinal, getNewAssessmentObject } from "./ProcessUtils";
import { isEmptyArray, isEmptyJson, isPositiveOrZero, removeFromArray } from "../utils/uiUtils";
import { getTestProviders } from "../api";
import { FormSectionHeader } from "../components/FormSectionHeader";
import { formSectionClasses } from "../components/ComponentStyles";
import { FormSection } from "./FormSection";
import { ApplicantAssessmentsToggle } from "./components/ApplicantAssessmentsToggle";
import { AssessmentLanguageSelection } from "./components/AssessmentLanguageSelection";

export function TestsAndMethodsForm({ formData, errors, fieldUpdateAction, updateAction, addNewItemAction, removeItemAction, validateAction, archived }) {
    const { t } = useTranslation();
    const [testProvidersLoading, setTestProvidersLoading] = useState(true);
    const [testProviders, setTestProviders] = useState([]);

    const assessmentsSectionKey = "processAssessments";
    const disabledApplicantAssessmentsSectionKey = "disabledApplicantAssessments";

    const disableApplicantAssessment = (candidateId, assessmentId, enabled) => {
        if (enabled === true) {
            let updatedItems = formData.disabledApplicantAssessments.filter(item => !(item.candidateId === candidateId && item.assessmentId === assessmentId));
            removeItemAction(disabledApplicantAssessmentsSectionKey, updatedItems);
        } else {
            const newItem = {
                candidateId: candidateId,
                assessmentId: assessmentId
            };
            addNewItemAction(disabledApplicantAssessmentsSectionKey, newItem);
        }
    };

    useEffect( () => {
        const fetchData = async () => {
            await getTestProviders().then((response) => {
                setTestProviders(response);
            }).catch((error) => {
                console.log(error);
                setTestProviders([]);
            }).finally(() => {
                setTestProvidersLoading(false);
            })
        }
        if (testProvidersLoading) {
            fetchData().catch(console.error);
        }
    }, [testProviders, testProvidersLoading]);

    const updateAssessments = (providerId, assessmentId, name, value, options, reports) => {
        const item = getAssessment(assessmentsSectionKey, providerId, assessmentId);
        item.name = name;
        item.value = value;
        item.options = (value === false) ? [] : item.options;
        item.reports = (value === false) ? [] : item.reports;
        updateProcessAssessments(item, assessmentsSectionKey, providerId, assessmentId, name, value, options, reports);
    }

    const getOptionValues = (existingOptions, option, optionType) => {
        if (isEmptyArray(existingOptions) || optionType === "radio") {
            return [option];
        }
        let optionsClone = cloneDeep(existingOptions);
        let newOptions = optionsClone?.slice();
        newOptions.push(option);
        return newOptions;
    }

    const updateAssessmentOptions = (providerId, assessmentId, name, isChecked, optionValue, optionType, optionsOnly = false) => {
        const item = getAssessment(assessmentsSectionKey, providerId, assessmentId);
        item.name = name;
        if (isChecked) {
            item.value = true;
            item.options = getOptionValues(item.options, optionValue, optionType);
        } else {
            item.options = removeFromArray(item.options, optionValue);
        }
        if (isEmptyArray(item.options) && optionsOnly) {
            item.value = false;
        }
        updateProcessAssessments(item, assessmentsSectionKey, providerId, assessmentId);
    }

    const updateAssessmentReports = (providerId, assessmentId, name, reportValues) => {
        const item = getAssessment(assessmentsSectionKey, providerId, assessmentId);
        item.name = name;
        item.reports = reportValues;
        if (!isEmptyArray(item.reports)) {
            item.value = true;
        }
        updateProcessAssessments(item, assessmentsSectionKey, providerId, assessmentId);
    }

    const findExistingAssessment = (sectionKey, providerId, assessmentId) => {
        return formData[sectionKey].find((item) => item.providerId === providerId && item.assessmentId === assessmentId);
    }

    const getAssessment = (sectionKey, providerId, assessmentId) => {
        const existing = findExistingAssessment(sectionKey, providerId, assessmentId);
        if (existing) {
            return existing;
        }
        const maxOrdinal= isEmptyArray(formData[sectionKey]) ? 0 : Math.max(...formData[sectionKey].map(item => item.ordinal));
        return getNewAssessmentObject(maxOrdinal + 1, providerId, assessmentId);
    }

    const updateProcessAssessmentsData = (item, providerId, assessmentId) => {
        const assessment = getAssessment(assessmentsSectionKey, providerId, assessmentId);
        assessment.assessmentData = unionBy([item], assessment.assessmentData, 'candidateId');

        const updatedFormSection = unionWith([assessment], formData[assessmentsSectionKey], (assessment) => assessment.providerId === providerId && assessment.assessmentId === assessmentId);
        updateAction(assessmentsSectionKey, updatedFormSection);
    }

    const updateProcessAssessments = (item, sectionKey, providerId, assessmentId) => {
        const updatedData = unionWith([item], formData[sectionKey], (item) => item.providerId === providerId && item.assessmentId === assessmentId);
        updateAction(sectionKey, updatedData);

        let position = getItemPositionByOrdinal(updatedData, item.ordinal);

        if (isPositiveOrZero(position)) {
            let item = updatedData[position];
            Object.keys(item).forEach(function(key) {
                validateAction(`${sectionKey}[${position}].${key}`, item[key]);
            });
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        fieldUpdateAction(name, value);
    };

    const applicantsWithCandidateId = formData.applicants.filter(applicant => applicant.candidateId);

    const applicantsForAssessment = (assessmentId) => {
        const disabledCandidateIds = new Set(
            formData.disabledApplicantAssessments
                .filter(disabledItem => disabledItem.assessmentId === assessmentId)
                .map(disabledItem => disabledItem.candidateId)
        );
        return applicantsWithCandidateId.filter(applicant => !disabledCandidateIds.has(applicant.candidateId));
    }

    return (
        <>
            <div className={ formSectionClasses }>
                <FormSectionHeader title={t("Process.TestsAndMethods.Section1.Title")} summary={t("Process.TestsAndMethods.Section1.Summary")}>
                    <div className="container mx-auto">
                        <AssessmentSummary
                            title={ t("Process.TestsAndMethods.Section1.Notification.Title") }
                            emptyContent={ t("Process.TestsAndMethods.Section1.Notification.NoneSelected") }
                            selectedAssessments={ formData.processAssessments }
                            testProviders={ testProviders }

                        />
                    </div>
                </FormSectionHeader>

                <div className="grid max-w-3xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                    <div className="col-span-full">
                        { testProvidersLoading &&  (
                            <div>
                                <LoadingIndicator type="small"/> <span className="pl-3">{ t("Overlay.Loading") }</span>
                            </div>
                        )}
                        <div className="container mx-auto">
                            { !isEmptyJson(testProviders) && testProviders.map((item, index) => (
                                <Fragment key={ `test_provider_${item.id}_key_${index}` }>
                                    { !isEmptyArray(item.assessments) && (
                                        <div className="w-full mb-4">
                                            <div className="py-3 pl-2 bg-mps-light-gray border-t border-b border-gray-300">
                                                { item.name }
                                            </div>
                                            { item.assessments && (
                                                <fieldset className="mt-4">
                                                    <legend className="sr-only">{ item.name }</legend>
                                                    <div className="space-y-2">
                                                        { item.assessments.filter(item => item.isObsolete === false).map((assessment, ind) => (
                                                            <div key={ `assessment_${item.id}_key_${ind}` } className="border-b border-gray-300 pb-2">
                                                                <Assessment
                                                                    providerId={ item.id }
                                                                    assessment={ assessment }
                                                                    updateAction={ updateAssessments }
                                                                    updateOptionsAction={ updateAssessmentOptions }
                                                                    updateReportsAction={ updateAssessmentReports }
                                                                    data={ formData.processAssessments }
                                                                    disabled={ archived }/>

                                                                { !isEmptyJson(assessment.languages?.values) && item.linkType === "Personal" && (
                                                                    <div className="w-full">
                                                                        {applicantsForAssessment(assessment.id).map((applicant) => (
                                                                            <AssessmentLanguageSelection
                                                                                key={`${item.id}_${assessment.id}_${applicant.candidateId}`}
                                                                                providerId={ item.id }
                                                                                assessment={ assessment }
                                                                                updateAction={ updateProcessAssessmentsData }
                                                                                formData={ formData.processAssessments }
                                                                                applicant={ applicant }
                                                                                disabled={ archived }
                                                                            />
                                                                        ))}
                                                                    </div>
                                                                )}

                                                                    { !item.integrated && item.linkType === "Personal" && (
                                                                        <div className="space-y-2">
                                                                            {applicantsWithCandidateId.map((applicant) => (
                                                                                <PersonalLink
                                                                                    key={`${item.id}_${assessment.id}_${applicant.candidateId}`}
                                                                                    providerId={ item.id }
                                                                                    assessment={ assessment }
                                                                                    updateAction={ updateProcessAssessmentsData }
                                                                                    formData={ formData.processAssessments }
                                                                                    applicant={ applicant }
                                                                                    disabled={ archived } />
                                                                            ))}
                                                                        </div>
                                                                    )}
                                                            </div>
                                                        ))}
                                                    </div>

                                                    { !item.integrated && item.linkType === "Shared" && (
                                                        <div className="space-y-2">
                                                            <SharedLink
                                                                provider={ item }
                                                                applicants={ applicantsWithCandidateId }
                                                                updateAction={ updateProcessAssessmentsData }
                                                                formData={ formData.processAssessments }
                                                                disabled={ archived }
                                                            />
                                                        </div>
                                                    )}

                                                </fieldset>
                                            )}
                                        </div>
                                    ) }
                                </Fragment>
                            ))}
                        </div>
                    </div>

                    <div className="col-span-full">
                        <TextArea
                            label= {t("Process.Properties.AssessmentInstructions")}
                            id="assessmentInstructions"
                            name="assessmentInstructions"
                            value={ formData.assessmentInstructions }
                            action={ handleChange }
                            rows={4}
                            error={ errors["assessmentInstructions"] }
                            disabled={ archived }
                        />
                    </div>
                </div>
            </div>

            <FormSection title={t("Process.TestsAndMethods.Section2.Title")} summary={t("Process.TestsAndMethods.Section2.Summary")}>
                <div className="grid max-w-3xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                    <div className="col-span-full">
                        {!isEmptyJson(testProviders) && (
                            <ApplicantAssessmentsToggle
                                formData={formData}
                                testProviders={ testProviders }
                                disableApplicantAssessmentAction={ disableApplicantAssessment }
                                disabled={ archived }
                            />
                        )}
                    </div>
                </div>
            </FormSection>
        </>
    );
}
