import { useTranslation } from "react-i18next";
import { TextInput } from "../components/TextInput";
import { useState } from "react";

export const PersonalLink = ({
  providerId,
  assessment,
  updateAction,
  formData,
  applicant,
  disabled,
}) => {
  const { t } = useTranslation();

  const currentAssessment = formData.find(
    (item) =>
      item.providerId === providerId && item.assessmentId === assessment.id
  );
  const existingCandidate = currentAssessment?.assessmentData.find(
    (item) => item.candidateId === applicant.candidateId
  );

  const [url, setUrl] = useState(existingCandidate?.url ?? "");

  const handleChange = (event) => {
    if (event.currentTarget.value === url) {
      return;
    }

    setUrl(event.currentTarget.value);
    updateAction(
      { candidateId: applicant.candidateId, url: event.currentTarget.value },
      providerId,
      assessment.id
    );
  };

  const inputLabel = `${applicant?.firstName} ${applicant?.lastName}: ${t(
    "Process.TestsAndMethods.NonIntegrated.Link"
  )}`;
  const id = `personalLink_${providerId}_${assessment.id}_${applicant.candidateId}`;

  return (
    <div className="mt-2">
      {currentAssessment?.value && applicant.candidateId ? (
        <div>
          <TextInput
            id={id}
            label={inputLabel}
            name={id}
            value={url}
            action={handleChange}
            disabled={disabled}
          />
        </div>
      ) : null}
    </div>
  );
};
