import React, { useRef, useEffect } from 'react';

export const ClickNotifier = ({ children, outOfComponentClickAction }) => {
    const containerRef = useRef(null);

    useEffect(() => {
        const handleExternalClick = (event) => {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                outOfComponentClickAction();
            }
        };

        document.addEventListener('click', handleExternalClick);

        return () => {
            document.removeEventListener('click', handleExternalClick);
        };
    }, [outOfComponentClickAction]);

    return (
        <div ref={containerRef}>{children}</div>
    );
};
