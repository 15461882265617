import { CheckIcon, PencilIcon, TrashIcon, XMarkIcon } from "@heroicons/react/24/solid";
import {classNames} from "../utils/uiUtils";

export const CancelIcon = ({ action }) => {
    return (
        <XMarkIcon
            className="-ml-8 h-5 w-5 flex-shrink-0 text-gray-900 rounded-full ring-1 ring-gray-300 ring-offset-4 hover:ring-red-800 hover:text-red-800 hover:ring-2"
            aria-hidden="true"
            onClick={ action } />
    );
};

export const ConfirmIcon = ({ action, hasChanges = false }) => {
    return (
        <CheckIcon
            aria-hidden="true"
            onClick={ action }
            className={classNames(
                hasChanges
                    ? 'text-black bg-indigo-100 hover:ring-indigo-800 hover:text-indigo-800'
                    : 'text-gray-900 hover:ring-green-800 hover:text-green-800',
                'ml-4 h-5 w-5 flex-shrink-0 rounded-full ring-1 ring-gray-300 ring-offset-4 hover:ring-2 '
            )}/>
    );
};

export const EditIcon = ({ action }) => {
    return (
        <PencilIcon
            className="ml-6 h-5 w-5 flex-shrink-0 text-gray-900 rounded-full ring-1 ring-gray-300 ring-offset-4 hover:ring-gray-600 hover:ring-2"
            aria-hidden="true"
            onClick={ action } />
    );
};

export const RemoveIcon = ({ action }) => {
    return (
        <TrashIcon
            className="-ml-0.5 h-5 w-5 rounded-full ring-1 ring-gray-300 ring-offset-4 hover:ring-gray-600 hover:ring-2 hover:cursor-pointer"
            aria-hidden="true"
            onClick={ action } />
    );
};
