import { Fragment } from "react";
import { LightBulbIcon } from "@heroicons/react/24/outline";
import { isEmptyArray } from "../utils/uiUtils";
import { getProviderNameByAssessmentId, sortAssessments, sortVerifyOptions } from "../process/ProcessUtils";
import { useTranslation } from "react-i18next";

export const AssessmentSummary = ({ title, emptyContent, selectedAssessments, testProviders }) => {
  const { t } = useTranslation();
  const sortedAssessments = sortAssessments(selectedAssessments);

  const getItemValue = (item) => {
    let providerName = getProviderNameByAssessmentId(testProviders, item.assessmentId);
    let optionNames = getSelectedOptions(item);
    return `${providerName}${item.name} ${optionNames}`;
  }

  const getSelectedOptions = (item) => {
    if (isEmptyArray(item.options)) {
      return "";
    }

    if (item.name === 'Verify') {
      return sortVerifyOptions(item.options);
    }

    return item.options.map(option => {
      return `${t(`Process.TestsAndMethods.Assessments.Options.${option}`,option)}`;
    }).join(",");
  }

  const hasAnyAssessments = () => {
    if (isEmptyArray(sortedAssessments)) {
      return false;
    }
    return sortedAssessments.some(item => item.value === true);
  }

  const hasAssessments = hasAnyAssessments();

  return (
    <>
      <div className="bg-blue-50 p-4 w-full border-l-4 border-mps-ultramarine">
        <div className="flex">
          <div className="flex-shrink-0">
            <LightBulbIcon
              className="h-5 w-5 border-l-blue-900"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-mps-ultramarine font-semibold">
              {title}
            </p>
          </div>
        </div>
        { hasAssessments && (
          <div className="flex mt-4 text-sm text-mps-ultramarine">
            <ul className="flex flex-col ml-8 list-disc">
              { sortedAssessments.filter(item => item.value === true).map((item, index) => (
                <li key={`selected_assessment_key_${index}`}>{ getItemValue(item) }</li>
              ))}
            </ul>
          </div>
        )}
        { !hasAssessments && (
          <div className="flex mt-4">
            <div className="ml-8 flex-1 md:flex md:justify-between">
              <p className="text-sm text-mps-ultramarine">{ emptyContent }</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
