import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DocumentCheckIcon } from "@heroicons/react/24/outline";
import { postRequest, blobStorageUpload } from "../api";

export const FileUpload = ({ id, label, sizeLimit, uri, fileType, attachmentType, action }) => {
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState("");
  const [error, setError] = useState("");

  const uploadFile = async (file) => {
    setError("");

    const response = await postRequest(uri, { fileName: file.name });
    if (response.uri) {
      setIsUploading(true);
      const result = await blobStorageUpload(
          response.uri,
          file,
          setUploadProgress
      );
      if (result) {
        action({
          fileName: file.name,
          uri: response.uri,
          attachmentType: attachmentType
        });
      }
    } else {
      setError(t("Errors.ErrorUploading"));
    }
    setIsUploading(false);
  }
  const onInputChange = (event) => {
    event.preventDefault();
    const file = event.target.files?.[0];
    const fileSize = (file?.size ?? 0) / 1024 / 1024;

    if (fileSize > sizeLimit) {
      setError(t("Errors.FileTooBig", { sizeLimit: sizeLimit }));
      return;
    }

    if (file) {
      uploadFile(file).catch(e => {
        console.log(e);
      });
    }
  };

  return (
    <div>
      <div className="text-sm mt-1 group flex items-start">
        <DocumentCheckIcon
          className="-ml-0.5 h-5 w-5 mr-1 pt-0.5 text-gray-400"
          aria-hidden="true"
        />
        <span className="truncate mr-2">
          {t("ProcessProgress.EvaluationReport.NotAdded")}
        </span>
        <label
          htmlFor={id}
          className="text-sm text-mps-ultramarine hover:text-blue-500 hover:cursor-pointer focus-visible:outline-gray-600 underline"
        >
          {label}
        </label>
        <input
          id={id}
          type="file"
          aria-label={label}
          aria-required="true"
          className="sr-only"
          accept={fileType}
          onChange={onInputChange}
          disabled={isUploading}
        />
        {isUploading && (
          <p className="ml-2 text-sm text-gray-600" id="file-upload-progress">
            {uploadProgress} %
          </p>
        )}
      </div>
      {error && (
        <p className="mt-2 text-sm text-red-600" id="file-upload-error">
          {error}
        </p>
      )}
    </div>
  );
};
