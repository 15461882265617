import { ApplicantAssessmentsToggleCard } from "./ApplicantAssessmentsToggleCard";
import { sortAssessments } from "../ProcessUtils";
import { isEmptyJson } from "../../utils/uiUtils";
import { useTranslation } from "react-i18next";

export const ApplicantAssessmentsToggle = ({
  formData,
  testProviders,
  disableApplicantAssessmentAction,
  disabled,
}) => {
  const { t } = useTranslation();
  const hasApplicants = !isEmptyJson(formData.applicants);

  const sortedAssessments = sortAssessments(
    formData.processAssessments.filter((item) => item.value === true)
  );

  const disabledAssessmentsForApplicant = (candidateId) => {
    return formData.disabledApplicantAssessments.filter(
      (item) => item.candidateId === candidateId
    );
  };

  return (
    <>
      <div className="container mx-auto w-full">
        <div className="grid grid-cols-2 gap-4 w-full">
          {hasApplicants &&
            formData.applicants.map((item, index) => (
              <div
                className="w-full float-left mb-4"
                key={`assessment_toggle_${index}_key_${item.ordinal}`}
              >
                <ApplicantAssessmentsToggleCard
                  applicant={item}
                  selectedAssessments={sortedAssessments}
                  testProviders={testProviders}
                  disableApplicantAssessmentAction={disableApplicantAssessmentAction}
                  disabledApplicantAssessments={disabledAssessmentsForApplicant(item.candidateId)}
                  disabled={disabled}
                />
              </div>
            ))}
        </div>
        {!hasApplicants && (
          <div className="w-full float-left text-sm">
            {t("Process.TestsAndMethods.Section2.NoApplicants")}
          </div>
        )}
      </div>
    </>
  );
};
