import { useTranslation } from "react-i18next";
import { TextInput } from "../components/TextInput";
import { useState } from "react";

export const SharedLinkCredentials = ({
  providerId,
  applicant,
  assessmentData,
  updateAction,
  disabled
}) => {
  const { t } = useTranslation();
  const candidateData = assessmentData?.find(
    (item) => item.candidateId === applicant.candidateId
  );

  const [username, setUsername] = useState(candidateData?.userName ?? "");
  const [password, setPassword] = useState(candidateData?.password ?? "");

  const handleUsernameChange = (event) => {
    if (event.currentTarget.value === username) {
      return;
    }
    setUsername(event.currentTarget.value);
    updateAction({
      candidateId: applicant.candidateId,
      userName: event.currentTarget.value,
      password: password,
    });
  };

  const handlePasswordChange = (event) => {
    if (event.currentTarget.value === password) {
      return;
    }
    setPassword(event.currentTarget.value);
    updateAction({
      candidateId: applicant.candidateId,
      userName: username,
      password: event.currentTarget.value,
    });
  };

  return (
    <div className="flex mt-4">
      {applicant.candidateId ? (
        <>
          <div className="w-1/2">
            <TextInput
              id={`username_${applicant.candidateId}_${providerId}`}
              label={`${applicant?.firstName} ${applicant?.lastName}: ${t(
                "Process.TestsAndMethods.NonIntegrated.UserName"
              )}`}
              name={`username_${applicant.candidateId}_${providerId}`}
              value={username}
              action={handleUsernameChange}
              disabled={disabled}
            />
          </div>
          <div className="ml-16 w-1/2">
            <TextInput
              id={`password_${applicant.candidateId}_${providerId}`}
              label={`${applicant?.firstName} ${applicant?.lastName}: ${t(
                "Process.TestsAndMethods.NonIntegrated.Password"
              )}`}
              name={`password_${applicant.candidateId}`}
              value={password}
              action={handlePasswordChange}
              disabled={disabled}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};
