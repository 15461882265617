import { useTranslation } from "react-i18next";
import { Button } from "./Button";
import { Required } from "./Required";
import { AutoComplete } from "./AutoComplete";
import { searchEmail } from "../api";

export const EmailGroup = ({ label, id, name, placeholder, items, action, addAction, removeAction, errors, required = false, disabled }) => {
    const { t } = useTranslation();
    const getError = (key, index) => {
        if (errors === undefined) {
            return;
        }

        let errorKey = getFieldId(key, index);
        if (Object.keys(errors).some(key => key === errorKey)) {
            return errors[errorKey];
        }
    }

    const getFieldId = (key, index) => {
        return `${key}[${index}].email`;
    }

    return (
        <>
            <label htmlFor={ getFieldId(id, 0) } className="block text-sm font-medium leading-6 text-gray-900">
                { label }{ required && required === true && <Required/> }
            </label>
            { items.map((item, index) => (
                <div key={ index } className="w-2/3">
                    <AutoComplete
                        id={ getFieldId(id, index) }
                        name={ name }
                        placeholder={ placeholder }
                        value={ item.email }
                        action={ action }
                        searchAction={ searchEmail }
                        removeAction={ removeAction }
                        error={ getError(name, index) }
                        index={ index }
                        ordinal={ item.ordinal }
                        disabled={ disabled }
                    />
                </div>
            ))}
            <div className="mt-2">
                <Button
                    text={ t("Actions.AddItem") }
                    icon={ "PlusIcon" }
                    type="secondary"
                    action={ addAction }
                    isDisabled={ disabled }
                />
            </div>
        </>
    );
};
