import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/solid";
import { ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { classNames } from "../utils/uiUtils";
import { InputErrorMessage } from "./InputErrorMessage";
import { Required } from "./Required";

export const SelectBox = ({ id, label, options, required, error, action, selected = 0, disabled = false, idProperty = "id", nameProperty = "name" }) => {
    const [selectedOption, setSelectedOption] = useState(options[selected])

    const onSelect = (e) => {
        setSelectedOption(e);
        let event = {
            target: {
                name: id,
                value: e[idProperty]
            }
        };
        action(event);
    }

    return (
        <div className="w-full">
            <Listbox value={selectedOption} onChange={ onSelect } disabled={disabled}>
                {({ open }) => (
                    <>
                        <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">{ label }{ required && required === true && <Required/> }</Listbox.Label>
                        <div className="relative mt-2">
                            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-mps-ultramarine sm:text-sm sm:leading-6">
                                <span className="block truncate">{ selectedOption ? selectedOption[nameProperty] : "" }</span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                            </Listbox.Button>

                            <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {options.map((option) => (
                                        <Listbox.Option
                                            key={option[idProperty]}
                                            className={({ active }) =>
                                                classNames(
                                                    active ? 'bg-mps-ultramarine/80 text-white' : 'text-gray-900',
                                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                                )
                                            }
                                            value={option}
                                        >
                                            {({ selected, active }) => (
                                                <>
                                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                      {option[nameProperty]}
                                                    </span>
                                                    {selected ? (
                                                        <span
                                                            className={classNames(
                                                                active ? 'text-white' : 'text-mps-ultramarine-600',
                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                            )}>
                                                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                        </span>
                                                    ) : null}
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </>
                )}
            </Listbox>
            <InputErrorMessage id={ `${id}-error` } error={ error } />
        </div>
    );
};
