import { useState } from "react";
import { classNames, highlightByFilter, isBlank, isNotBlank } from "../utils/uiUtils";
import { CancelIcon, ConfirmIcon, EditIcon, RemoveIcon } from "./Icons";
import { InputErrorMessage } from "./InputErrorMessage";
import { textInputClasses, textInputCommonClasses, textInputErrorClasses } from "./ComponentStyles";

export const CustomCriteriaCard = ({ id, title, content, ordinal, titleError, contentError, removeAction, onEditConfirmedAction, filterTerm }) => {
    const [editMode, setEditMode] = useState((isBlank(title) || isNotBlank(titleError) || isNotBlank(contentError)));
    const [currentContent, setCurrentContent] = useState(content.slice());
    const [currentTitle, setCurrentTitle] = useState(title.slice());

    const onRemoveClicked = (e, ordinal) => {
        e.preventDefault();
        removeAction(e, "customCriteria", ordinal);
    };

    const onEditClicked = (e) => {
        e.preventDefault();
        setEditMode(true);
    };

    const onEditCancelled = (e) => {
        e.preventDefault();
        setCurrentTitle(title.slice());
        setCurrentContent(content.slice());
        closeEditMode();
    }

    const onTitleEdit = (e) => {
        e.preventDefault();
        const { value } = e.target;
        setCurrentTitle(value);
    }

    const onContentEdit = (e) => {
        e.preventDefault();
        const { value } = e.target;
        setCurrentContent(value);
    }

    const closeEditMode = () => {
        setEditMode(false);
    }

    const onEditConfirmed = (e, ordinal) => {
        e.preventDefault();
        if (hasUnsavedChanges()) {
            onEditConfirmedAction("customCriteria", ordinal, currentTitle, currentContent);
        }
        closeEditMode();
    }

    const hasUnsavedChanges = () => {
        return isBlank(currentTitle) || isBlank(currentContent) || currentTitle !== title || currentContent !== content;
    }

    const onCardSelect = (e) => {
        e.preventDefault();
    }

    const highlightMatchingText = (text) => {
        return highlightByFilter(text, filterTerm);
    };

    return (
        <div className="flex flex-wrap">
            <input type="checkbox"
                   id={ id }
                   value={ ordinal }
                   checked={ true }
                   onChange={ (event) => onCardSelect(event) }
                   className="hidden peer" />
            <label htmlFor={ id }
                   className={classNames(
                       titleError || contentError
                           ? "peer-checked:border-red-500"
                           : "peer-checked:border-mps-content",
                       "top-0 inline-flex items-center justify-between w-full p-5 text-gray-900 bg-white border-2 border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 hover:text-gray-600 dark:peer-checked:text-gray-300 peer-checked:text-gray-900 hover:bg-gray-50 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700"
                   )}>
                <div className="block w-full">
                    <div className="w-full text-base flex items-center justify-between">
                        <div className="text-left w-11/12">
                            { editMode &&  (
                                <input
                                    type="text"
                                    id={ `${id}.title` }
                                    name={ `${id}.title` }
                                    value={ currentTitle }
                                    onChange={ (event) => onTitleEdit(event) }
                                    className={classNames(
                                        titleError
                                            ? textInputErrorClasses
                                            : textInputClasses,
                                        textInputCommonClasses,
                                        'w-11/12'
                                    )}
                                />
                            )}
                            { !editMode &&  <span className="text-lg font-semibold">{ highlightMatchingText(currentTitle) }</span> }
                        </div>
                        { editMode &&  (
                            <div className="flex items-center space-x-4 ">
                                <CancelIcon action={ (event) => onEditCancelled(event) } />
                                <ConfirmIcon action={ (event) => onEditConfirmed(event, ordinal) } />
                            </div>
                        )}
                        { !editMode &&  (
                            <div className="flex items-center space-x-4">
                                <RemoveIcon action={ (event) => onRemoveClicked(event, ordinal) } />
                                <EditIcon action={ (event) => onEditClicked(event) } />
                            </div>
                        )}
                    </div>
                    { titleError &&
                        <div className="w-full"><InputErrorMessage id={ `${id}-error-content` } error={ titleError } /></div>
                    }
                    { editMode &&  (
                        <div className="w-full text-sm pt-4">
                            <textarea
                                id={ `${id}.content` }
                                name={ `${id}.content` }
                                value={ currentContent }
                                onChange={ (event) => onContentEdit(event) }
                                rows={3}
                                className={classNames(
                                    contentError
                                        ? "focus:ring-red-600"
                                        : "focus:ring-blue-600",
                                    "w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                                )}
                            />
                        </div>
                    )}
                    { !editMode &&  (
                        <div className="w-full text-sm pt-4">
                            { highlightMatchingText(currentContent) }
                        </div>
                    )}
                    { contentError && <InputErrorMessage id={ `${id}-error-content` } error={ contentError } /> }
                </div>
            </label>
        </div>
    );
};
