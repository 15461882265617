import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { isBlank } from "../utils/uiUtils";

export default function CriteriaFilter({ label, placeholder, criteriaTemplates, customCriteria, templateFilterAction }) {
    const onFilterChange = (e) => {
        let value = e.target.value;
        if (value.length >= 3) {
            const templateIds = criteriaTemplates.reduce((results, category) => {
                const matchingIds = category.templates
                    .filter(template =>
                        template.title.toLowerCase().includes(value.toLowerCase()) || template.content.toLowerCase().includes(value.toLowerCase())
                    )
                    .map(template => template.id);
                return results.concat(matchingIds);
            }, []);
            const customCriteriaIds = customCriteria.filter(c =>
                    c.title.toLowerCase().includes(value.toLowerCase()) || c.content.toLowerCase().includes(value.toLowerCase())
                )
                .map(c => c.ordinal);
            templateFilterAction(templateIds, customCriteriaIds, value);
        } else if (isBlank(value)) {
            templateFilterAction([], [], value);
        }
    }

    return (
        <div className="w-full sm:max-w-xs gap-2">
            <label htmlFor="filterCriteria" className="text-gray-800 font-normal text-sm">
                { label }
            </label>
            <div className="relative">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                    id="filterCriteria"
                    name="filterCriteria"
                    className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-mps-ultramarine sm:text-sm sm:leading-6"
                    placeholder={ placeholder }
                    type="search"
                    onChange={ (event) => onFilterChange(event) }
                />
            </div>
        </div>
    )
}
