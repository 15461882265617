import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { useTranslation } from 'react-i18next';
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { Button } from "./Button";
import { UserProfile } from "./UserProfile";
import { classNames, isNotBlank } from "../utils/uiUtils";
import { useUserContext } from '../UserContextProvider';

const activeClass =
  "inline-flex items-center border-b-2 border-indigo-500 px-1 pt-1 text-sm font-medium text-gray-900";
const pendingClass =
  "inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-sm font-medium text-gray-500 hover:border-gray-500 hover:text-gray-700";

export const NavMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { hasTalentHubRole, hasAdminOrCoordinatorRole, loginRedirect, logoutRedirect } = useUserContext();

  const envName = process.env.REACT_APP_ENV;
  const disableNavi = location.pathname === "/accessDenied" || location.pathname === "/redirect";

  const canAccessTranslations = hasAdminOrCoordinatorRole;

  const startNewProcess = () => {
    navigate("/process/new", { replace: false });
  };

  const showNewProcess = () => {
    if (disableNavi) {
      return false;
    }
    return location.pathname !== "/translations";
  }

  const showEnvName = () => {
    return isNotBlank(envName) && envName !== "Prod";
  }

  return (
      <Disclosure as="nav" className="bg-white shadow drop-shadow-sm">
        {({ open }) => (
            <>
              <UnauthenticatedTemplate>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                  <div className="flex h-16 justify-between">
                    <div className="flex">
                      <div className="-ml-2 mr-2 flex items-center md:hidden">
                      </div>
                      <div className="flex flex-shrink-0 items-center">
                        <img src="/images/mps-logo-blue.svg" className="h-10 w-auto" alt="MPS" title="MPS logo"/>
                      </div>
                    </div>
                      <div className="hidden md:ml-4 md:flex md:flex-shrink-0 md:items-center">
                        <Button
                            text={t("Navigation.Login")}
                            action={ loginRedirect }
                        />
                    </div>
                  </div>
                </div>
              </UnauthenticatedTemplate>
              <AuthenticatedTemplate>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                  <div className="flex h-16 justify-between">
                    <div className="flex">
                      <div className="-ml-2 mr-2 flex items-center md:hidden">
                        {/* Mobile menu button */}
                        <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                          <span className="absolute -inset-0.5" />
                          <span className="sr-only">{t("Navigation.OpenMainMenu")}</span>
                          {open ? (
                              <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                          ) : (
                              <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                          )}
                        </Disclosure.Button>
                      </div>
                      <div className="flex flex-shrink-0 items-center">
                        <img src="/images/mps-logo-blue.svg" className="h-10 w-auto" alt="MPS" title="MPS logo"/>
                      </div>
                      {!disableNavi && (
                        <div className="hidden md:ml-6 md:flex md:space-x-8">
                          { hasTalentHubRole && (
                              <>
                                <NavLink
                                  to="/"
                                  className={({ isActive }) => {
                                    return isActive
                                        ? classNames(activeClass)
                                        : classNames(pendingClass);
                                  }}
                              >
                                {t("Navigation.Dashboard")}
                              </NavLink>
                                <NavLink
                                    to="/search"
                                    className={({ isActive }) => {
                                      return isActive
                                          ? classNames(activeClass)
                                          : classNames(pendingClass);
                                    }}
                                >
                                  {t("Navigation.Search")}
                                </NavLink>
                              </>
                          )}
                          { canAccessTranslations && (
                              <NavLink
                                  to="/translations"
                                  className={({ isActive }) => {
                                    return isActive
                                        ? classNames(activeClass)
                                        : classNames(pendingClass);
                                  }}
                              >
                                {t("Navigation.Translations")}
                              </NavLink>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="flex items-center">
                      { showEnvName && (
                          <div className="space-y-1 p-2 mr-6 bg-mps-sun sm:rounded">
                            {envName}
                          </div>
                      )}
                      {showNewProcess() && (
                        <div className="flex-shrink-0">
                          <Button
                              text={t("Process.CreateBtn")}
                              action={ startNewProcess }
                              icon={"PlusIcon"}
                          />
                      </div>
                      )}
                      <div className="hidden md:ml-4 md:flex md:flex-shrink-0 md:items-center">
                        {/* Profile dropdown */}
                        <Menu as="div" className="relative ml-3">
                          <div>
                            <Menu.Button className="relative flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                              <span className="absolute -inset-1.5" />
                              <span className="sr-only">{t("Navigation.OpenMainMenu")}</span>
                              <UserProfile />
                            </Menu.Button>
                          </div>
                          <Transition
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <Menu.Item>
                                {({ active }) => (
                                    <button
                                        onClick={logoutRedirect}
                                        className={classNames(
                                            active ? 'bg-gray-100' : '',
                                            'block px-4 py-2 text-sm text-gray-700 w-full'
                                        )}
                                    >
                                      {t("Navigation.Logout")}
                                    </button>
                                )}
                              </Menu.Item>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </div>
                  </div>
                </div>

                <Disclosure.Panel className="md:hidden">
                  <div className="space-y-1 pb-3 pt-2">
                    {/* Current: "bg-blue-50 border-blue-500 text-blue-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                    <Disclosure.Button
                        as="a"
                        href="/"
                        className="block border-l-4 border-blue-500 bg-blue-50 py-2 pl-3 pr-4 text-base font-medium text-blue-700 sm:pl-5 sm:pr-6"
                    >
                      {t("Navigation.Dashboard")}
                    </Disclosure.Button>
                  </div>
                  <div className="border-t border-gray-200 pb-3 pt-4">
                    <div className="mt-3 space-y-1">
                      <Disclosure.Button
                          as="a"
                          onClick={logoutRedirect}
                          className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800 sm:px-6"
                      >
                        {t("Navigation.Logout")}
                      </Disclosure.Button>
                    </div>
                  </div>
                </Disclosure.Panel>
              </AuthenticatedTemplate>
            </>
        )}
      </Disclosure>
  )
}
