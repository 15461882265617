import { FormSectionHeader } from "../components/FormSectionHeader";
import { formSectionClasses } from "../components/ComponentStyles";

export function FormSection({ title, summary, children }) {

    return (
        <>
            <div className={ formSectionClasses }>
                <FormSectionHeader title={ title } summary={ summary } />
                { children }
            </div>
        </>
    );
}
