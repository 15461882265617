export const HiddenField = ({ id, name, value, action}) => {
    return (
        <>
            <input
                type="hidden"
                id={ id }
                name={ name }
                value={ value }
                onChange={ action }
            />
        </>
    );
};
