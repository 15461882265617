import { useEffect, useState } from "react";
import { TextInput } from "./TextInput";
import { classNames } from "../utils/uiUtils";

const NO_INDEX = -1;
const NO_ORDINAL = -1;

export const AutoComplete = ({ label, id, name, placeholder, value, action, searchAction, removeAction, error, required = false, index = NO_INDEX, ordinal = NO_ORDINAL, disabled = false }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [dataLoading, setDataLoading] = useState(false);
    const [suggestionsActive, setSuggestionsActive] = useState(false);
    const [cursor, setCursor] = useState(-1);

    const itemValue = (item) => {
        return item.name || item.value;
    }

    const simpleField = () => {
        return index === NO_INDEX && ordinal === NO_ORDINAL;
    }

    const onSuggestionClicked = (event, suggestion) => {
        event.preventDefault();
        resetSuggestions();
        if (simpleField()) {
            action(name, suggestion);
        } else {
            action(name, suggestion, index, ordinal);
        }
    };

    const resetSuggestions = () => {
        setSuggestions([]);
        setSuggestionsActive(false);
        setSearchTerm('');
        setCursor(-1);
    }

    const onFieldValueChanged = (e, searchEnabled = true) => {
        const { value } = e.target;
        if (value.length < 3) {
            resetSuggestions();
        }
        if (searchEnabled) {
            setSearchTerm(value);
        }

        if (simpleField()) {
            action(name, value);
        } else {
            action(name, value, index, ordinal);
        }
    };

    const onFieldKeyDown = (event) => {
        if (event.keyCode === 13) { // Enter
            resetSuggestions();
            if (cursor !== -1) {
                let e = {
                    target: {
                        name: id,
                        value: itemValue(suggestions[cursor])
                    }
                };
                onFieldValueChanged(e, false);
            }
        }
        if (event.keyCode === 27) { // Esc
            resetSuggestions();
        }
        if (event.keyCode === 38) { // Up arrow
            if (cursor === 0) return;
            setCursor(cursor - 1);
        } else if (event.keyCode === 40) { // Down arrow
            if (cursor - 1 === suggestions.length) return;
            setCursor(cursor + 1);
        }
    }

    const isActiveField = () => {
        return id === document.activeElement.id;
    }

    const suggestionsVisible = () => {
        return suggestionsActive && suggestions.length > 0 && isActiveField();
    }

    const showLoadingIndicator = () => {
        return dataLoading && isActiveField();
    }

    const removeIconName = (index) => {
        return (index > 0) ? "TrashIcon" : undefined;
    }

    const stretchField = () => {
        // Simple fields will take all available space, grouped fields need additional space for remove icon
        return simpleField();
    }

    const callRemoveWhenApplicable = (event) => {
        if (removeAction) {
            removeAction(event, name, ordinal);
        }
    }

    useEffect( () => {
        const fetchData = async () => {
            setDataLoading(true);
            setCursor(-1);
            await searchAction(searchTerm).then((response) => {
                setSuggestions(response);
                setSuggestionsActive(true);
            }).catch(() => {
                resetSuggestions();
            }).finally(() => {
                setSearchTerm('');
                setDataLoading(false);
            })
        }
        if (searchTerm.length >= 3 && !dataLoading && suggestions.length === 0 && searchTerm.length % 3 === 0) {
            fetchData().catch(() => {
                resetSuggestions();
                console.error();
            })
        }
    }, [searchAction, searchTerm, suggestions, dataLoading, suggestionsActive, cursor]);

    return (
        <>
            <TextInput
                label={ label }
                id={ id }
                name={ name }
                placeholder={ placeholder }
                value={ value }
                action={ onFieldValueChanged }
                keyDownAction={ onFieldKeyDown }
                required={ required }
                error={ error }
                loading={ showLoadingIndicator() }
                additionalIcon={ removeIconName(index) }
                additionalIconAction={ callRemoveWhenApplicable }
                suppressErrors={ showLoadingIndicator() || suggestionsVisible() }
                stretch={ stretchField() }
                disabled={ disabled }
            />
            { suggestionsVisible() && (
                <ul className="absolute z-10 border border-gray-300 bg-white rounded-md shadow-md max-w-full">
                    { suggestions.map((suggestion, index) => (
                        <li key={index}
                            onClick={(event) => onSuggestionClicked(event, itemValue(suggestion))}
                            onMouseEnter={() => setCursor(index)}
                            onMouseLeave={() => setCursor(-1)}
                            className={classNames(
                                index === cursor ? 'bg-gray-200' : '',
                                'py-1 px-2 cursor-pointer hover:bg-gray-200'
                            )}>
                            { itemValue(suggestion) }
                        </li>
                    ))}
                </ul>
            )}
        </>
    )
}
