import React, { Fragment, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { initUser } from "../api";
import { useTranslation } from "react-i18next";
import { isNotBlank } from "../utils/uiUtils";
import { Overlay } from "../components/Overlay";

export const LoginRedirect = () => {
  const { instance } = useMsal();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [traceId, setTraceId] = useState("");
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(true);

  const unknownError = t("Login.UnknownError");
  const accessDeniedError = t("Login.AccessDeniedError");
  const loadingMessage = t("Login.LoadingMessage");

  useEffect(() => {
    const handleRedirect = async () => {
      await initUser()
        .then(async (response) => {
          if (response?.status === 200) {
            navigate(`/`, {replace: true});
          } else if (response?.status === 400) {
            const responseBody = await response.json();
            setError(responseBody.detail);
            setTraceId(responseBody.traceId);
          } else if (response?.status === 401 || response?.status === 403) {
            navigate(`/accessDenied`, { replace: true });
          } else {
            setError(unknownError);
          }
        })
        .catch((error) => {
          console.error("Error handling redirect:", error);
          setError(unknownError);
        })
        .finally(() => {
          setShowLoadingIndicator(false);
        });
    };

    if (instance.getActiveAccount() && showLoadingIndicator) {
      handleRedirect().catch((error) => {
        console.error(error);
        setError(unknownError);
        setShowLoadingIndicator(false);
      });
    }
  }, [
    instance,
    navigate,
    error,
    showLoadingIndicator,
    unknownError,
    accessDeniedError,
  ]);

  return (
    <>
      <div className="flex justify-center mt-24">
        {isNotBlank(error) && (
          <Fragment>
            <div className="text-center">
              <p className="mt-2 text-sm text-red-600">
                {t("Errors.LoginError")}
              </p>
              <p className="mt-2 text-sm text-gray-700">{error}</p>
              {isNotBlank(traceId) && (
                <p className="mt-2 text-sm text-gray-700">
                  {t("Errors.TraceId")}: {traceId}
                </p>
              )}
            </div>
          </Fragment>
        )}
      </div>
      <Overlay isActive={showLoadingIndicator} message={loadingMessage} />
    </>
  );
};
