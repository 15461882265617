export const FormSectionHeader = ({ title, summary, children }) => {
    return (
        <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">{ title }</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600 whitespace-pre-line">
                { summary }
            </p>
            { children }
        </div>
    );
};
