export const RadioButton = ({ label, id, name, value, action, checked, disabled }) => {
    return (
        <div className="basis-2/4 w-full p-2">
            <input
                id={ id }
                name={ name }
                type="radio"
                value={ value }
                onChange={ action }
                checked={ checked }
                className="h-4 w-4 border-gray-300 text-mps-ultramarine focus:ring-mps-ultramarine focus:ring-offset-1 focus:ring-1"
                disabled={ disabled }
            />
            <label htmlFor={ id } className="inline-block text-sm font-medium leading-6 text-gray-900 pl-2">
                { label }
            </label>
        </div>
    );
};
