import { useTranslation } from "react-i18next";
import { isEmptyJson } from "../../utils/uiUtils";
import { Attachment } from "../../components/Attachment";

export const ApplicantAttachments = ({ attachments }) => {
  const { t } = useTranslation();

  return (
    <>
      {isEmptyJson(attachments) ? (
        <div className="text-gray-700 text-sm leading-4">
          {t("ProcessProgress.NoAttachments")}
        </div>
      ) : (
        <ul className="list-none text-gray-700">
          {attachments?.map((item) => (
            <li
              className="flex items-center justify-between py-1 pl-1 pr-1 text-sm leading-4"
              key={`attachment_key_${item.id}`}
            >
              <Attachment item={item} />
            </li>
          ))}
        </ul>
      )}
    </>
  );
};
