import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { isMoreThanDaysFromToday } from "../../utils/uiUtils";
import { DatePickerField } from "../../components/DatePickerField";
import { InputErrorMessage } from "../../components/InputErrorMessage";
import { Required } from "../../components/Required";

export const Timeframe = ({
  title,
  updateAction,
  fieldPrefix,
  startDate,
  endDate,
  startError,
  endError,
  required,
  disabled,
}) => {
  const { t } = useTranslation();

  const timeframeEndWarning = t(
    "Process.Interviews.Section1.TimeframeEndWarning",
  );
  const showTimeframeEndWarning = isMoreThanDaysFromToday(endDate, 30);

  return (
    <>
      <legend className="text-sm font-semibold leading-6 text-gray-900">
        {title}
        {required && required === true && <Required />}
      </legend>
      <div className="mt-2 flex flex-row flex-wrap columns-2xl">
        <div className="flex items-center">
          <div className="relative">
            <DatePickerField
              id={`${fieldPrefix}.timeframeStart`}
              name={`${fieldPrefix}.timeframeStart`}
              value={startDate}
              placeholder={t("Process.Applicants.Placeholders.Date")}
              updateAction={(event) => updateAction(event, "timeframeStart")}
              error={startError}
              disabled={ disabled }
            />
          </div>
          <span className="mx-1 text-gray-500">-</span>
          <div className="relative">
            <DatePickerField
              id={`${fieldPrefix}.timeframeEnd`}
              name={`${fieldPrefix}.timeframeEnd`}
              value={endDate}
              placeholder={t("Process.Applicants.Placeholders.Date")}
              updateAction={(event) => updateAction(event, "timeframeEnd")}
              error={endError}
              disabled={ disabled }
            />
          </div>
        </div>
      </div>
      {showTimeframeEndWarning && (
        <div className="text-red-600 mt-2 text-sm">{timeframeEndWarning}</div>
      )}
      <InputErrorMessage
        id={`${fieldPrefix}.timeframeEnd-error`}
        error={endError}
      />
    </>
  );
};
