import { ChevronRightIcon, HomeIcon } from '@heroicons/react/20/solid'
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function BreadCrumbs({ breadcrumbs }) {
    const { t } = useTranslation();
    return (
        <nav className="flex" aria-label="Breadcrumb">
            <ol className="flex items-center space-x-4">
                <li>
                    <div>
                        <Link to={"/"} className="text-gray-400 hover:text-gray-500">
                            <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                            <span className="sr-only">{ t("BreadCrumbs.Home") }</span>
                        </Link>
                    </div>
                </li>
                { breadcrumbs.map((breadcrumb) => (
                    <li key={ breadcrumb.label }>
                        <div className="flex items-center">
                            <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            <Link to={ breadcrumb.href }
                                  className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                                { breadcrumb.label }
                            </Link>
                        </div>
                    </li>
                ))}
            </ol>
        </nav>
    )
}
