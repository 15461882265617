import { CheckCircleIcon, ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import { classNames } from "../utils/uiUtils";
import { Link } from "react-router-dom";
import { matchingSectionIds, removeUndefinedEntries } from "../process/ProcessUtils";

export default function Tabs({ tabs, action, showIcon = true, theme = 'underline', errors = {} }) {
    const { t } = useTranslation();
    const randomId = crypto.randomUUID();

    const cleanedErrors = removeUndefinedEntries(errors);
    const tabsWithError = matchingSectionIds(Object.keys(cleanedErrors));
    const handleTabClickEvent = (e, id) => {
        e.preventDefault();
        action(id);
    };

    const tabClasses = (tab) => {
        if (theme === 'pills') {
            return classNames(
                tab.current ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700',
                'rounded-md px-3 py-2 text-sm font-medium'
            );
        }
        return classNames(
            tab.current
                ? 'border-indigo-500 text-indigo-600'
                : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
            'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium'
        );
    }

    const containerClassName = () => {
        if (theme === 'pills') {
            return classNames('border-none');
        }
        return classNames('border-b border-gray-200');
    }

    const showCheckIcon = (index) => {
        return showIcon && !tabsWithError.includes(index);
    }

    const showErrorIcon = (index) => {
        return showIcon && tabsWithError.includes(index);
    }

    return (
        <>
            <div>
                <div className="sm:hidden">
                    <label htmlFor={`tabs_${randomId}`} className="sr-only">
                        {t("Tabs.SelectTab")}
                    </label>
                    <select
                        id={`tabs_${randomId}`}
                        name={`tabs_${randomId}`}
                        className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                        defaultValue={ tabs.find((tab) => tab.current).name }
                    >
                        { tabs.map((tab) => (
                            <option key={tab.name}>{tab.name}</option>
                        ))}
                    </select>
                </div>
                <div className="hidden sm:block">
                    <div className={ containerClassName() }>
                        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                            { tabs.map((tab) => (
                                <Link to={ window.location.pathname }
                                      key={tab.name}
                                      onClick={(event) => handleTabClickEvent(event, tab.index)}
                                      className={ tabClasses(tab) }>
                                    { showCheckIcon(tab.index) &&  (
                                        <CheckCircleIcon
                                            className={classNames(
                                                'text-gray-400 group-hover:text-gray-500',
                                                '-ml-0.5 mr-2 h-5 w-5'
                                            )}
                                            aria-hidden="true"
                                        />
                                    )}
                                    { showErrorIcon(tab.index) &&  (
                                        <ExclamationCircleIcon
                                            className={classNames(
                                                "-ml-0.5 mr-2 h-5 w-5 text-red-700"
                                            )}
                                            aria-hidden="true"
                                        />
                                    )}
                                    <span className="sr-only">{ t("BreadCrumbs.Home") }</span>
                                    {tab.name}
                                </Link>
                            ))}
                        </nav>
                    </div>
                </div>
            </div>
        </>
    )
}
