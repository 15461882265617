import { isBlank, isEmptyArray, isNotBlank } from "../utils/uiUtils";

const propertiesBySection = [
    { index: 1, keys: ["customer", "jobTitle", "assessmentLevel", "serviceType", "schedule", "consultants", "forepersons", "reportRecipients", "coordinators"] },
    { index: 2, keys: ["individualInterviews", "groupSimulations", "individualSimulations"] },
    { index: 3, keys: ["processAssessments", "assessmentInstructions"] },
    { index: 4, keys: ["criteria", "customCriteria"] },
    { index: 5, keys: ["applicants"] }
];

const shlVerifySortOrder = ['I', 'N', 'D'];

const emptyApplicant = {
    ordinal: -1,
    candidateId: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    languageCode: 'fi',
    officeId: 1,
    individualInterviewDate: '',
    individualInterviewTime: '',
    individualInterviewBooking: undefined,
    individualSimulationDate: '',
    individualSimulationTime: '',
    groupSimulationDate: '',
    groupSimulationTime: '',
    notes: '',
    invitationSent: null,
    invitationEnabled: true
};

const emptyEmployee = {
    ordinal: 1,
    email: ''
};

const emptyIndividualInterview = {
    ordinal: -1,
    interviewType: 'None',
    duration: null,
    timeframeStart: '',
    timeframeEnd: ''
};

const emptyGroupSimulation = {
    ordinal: -1,
    interviewType: 'None',
    notes: '',
    language: '',
    timeframeStart: '',
    timeframeEnd: ''
};

const emptyIndividualSimulation = {
    ordinal: -1,
    showInPortal: false,
    interviewType: 'None',
    notes: ''
};

const emptyAssessment = {
    ordinal: -1,
    providerId: -1,
    assessmentId: -1,
    name: '',
    value: '',
    options: [],
    reports: [],
    assessmentData: []
}

export const employeeTypes = ["consultants", "forepersons", "reportRecipients", "coordinators"];

function getNewItemWithOrdinal(emptyObjectJson, ordinal) {
    let newItem = JSON.parse(JSON.stringify(emptyObjectJson));
    newItem.ordinal = ordinal;

    return newItem;
}

export function getNewIndividualInterviewObject(ordinal) {
    return getNewItemWithOrdinal(emptyIndividualInterview, ordinal);
}

export function getNewIndividualSimulationObject(ordinal) {
    return getNewItemWithOrdinal(emptyIndividualSimulation, ordinal);
}

export function getNewGroupSimulationObject(ordinal) {
    return getNewItemWithOrdinal(emptyGroupSimulation, ordinal);
}

export function getNewApplicantObject(ordinal) {
    let newApplicant = JSON.parse(JSON.stringify(emptyApplicant));
    newApplicant.ordinal = ordinal;

    return newApplicant;
}

export function getNewAssessmentObject(ordinal, providerId, assessmentId) {
    let item = getNewItemWithOrdinal(emptyAssessment, ordinal);
    item.providerId = providerId;
    item.assessmentId = assessmentId;
    return item;
}

export function cloneApplicant(applicantData) {
    let applicant = JSON.parse(JSON.stringify(emptyApplicant));

    Object.keys(applicantData).forEach(function(key) {
        applicant[key] = applicantData[key];
    });

    return applicant;
}

export function getNewEmployeeObject() {
    return JSON.parse(JSON.stringify(emptyEmployee));
}

export function getInitialForm(type, id, initialState) {
    return {
        type: type,
        ...(id ? { id } : {}),
        processState: initialState,
        customer: {
            name: "",
        },
        jobTitle: "",
        assessmentLevel: "None",
        serviceType: "None",
        schedule: "",
        consultants: [getNewEmployeeObject()],
        forepersons: [getNewEmployeeObject()],
        reportRecipients: [getNewEmployeeObject()],
        coordinators: [getNewEmployeeObject()],
        criteria: [],
        customCriteria: [],
        applicants: [],
        individualInterviews: [getNewIndividualInterviewObject(1)],
        individualSimulations: [getNewIndividualSimulationObject(1)],
        groupSimulations: [getNewGroupSimulationObject(1)],
        processAssessments: [],
        assessmentInstructions: "",
        disabledApplicantAssessments: []
    }
}

export function getFieldError(errors, fieldPrefix, fieldSuffix){
    let errorKey = `${fieldPrefix}.${fieldSuffix}`;
    return getErrorForKey(errors, errorKey);
}

export function getErrorForKey(errors, errorKey){
    if (errors === undefined) {
        return;
    }

    if (Object.keys(errors).some(key => key === errorKey)) {
        return errors[errorKey];
    }
}

export function getItemPositionByOrdinal(items, ordinal) {
    return items.findIndex(
        entry => entry.ordinal === ordinal
    );
}

export function sortAssessments(assessments) {
    if (isEmptyArray(assessments)) {
        return assessments;
    }
    return [...assessments].sort((a, b) => {
        if (a.providerId === b.providerId) {
            return a.assessmentId - b.assessmentId;
        }
        return a.providerId - b.providerId;
    });
}

export function isEmptyApplicant(applicant) {
    return isBlank(applicant.firstName) &&
        isBlank(applicant.lastName) &&
        isBlank(applicant.email) &&
        isBlank(applicant.phoneNumber);
}

export function matchingSectionIds(keys) {
    const matchingIndexes = [];
    propertiesBySection.forEach((section) => {
        const matches = keys.some((searchKey) =>
            section.keys.some((sectionKey) => searchKey.startsWith(sectionKey))
        );
        if (matches) {
            matchingIndexes.push(section.index);
        }
    });
    return matchingIndexes;
}

export function removeUndefinedEntries(obj) {
    return Object.fromEntries(
        Object.entries(obj).filter(
            ([key, value]) => value !== undefined
        )
    )
}

export function sortVerifyOptions(options) {
    if (isEmptyArray(options)) {
        return '';
    }
    const sortedOptions = options
        .filter(option => shlVerifySortOrder.includes(option))
        .sort((a, b) => shlVerifySortOrder.indexOf(a) - shlVerifySortOrder.indexOf(b));
    return parenthesize(sortedOptions.join(','));
}

export function parenthesize(value) {
    return `(${value})`;
}

export function assessmentStatusColor(status) {
    return status === "None" || status === "NotStarted"
        ? "text-red-500"
        : status === "Completed"
            ? "text-green-500"
            : "text-yellow-500";
}

export function assessmentStatus(status) {
    return `ProcessProgress.Status.${status}`;
}

export function getProviderNameByAssessmentId(testProviders, assessmentId) {
    if (isEmptyArray(testProviders)) {
        return "";
    }
    for (let provider of testProviders) {
        const foundOption = provider.assessments.find(assessment => assessment.id === assessmentId);
        if (foundOption) {
            return `${provider.name}: `;
        }
    }
    return "";
}

export function addMissingProperties(response, employeeTypes) {
    employeeTypes.forEach((et) => {
        if (isEmptyArray(response[et])) {
            response[et] = [getNewEmployeeObject()];
        }
    })
    if (isEmptyArray(response.individualInterviews)) {
        response.individualInterviews = [getNewIndividualInterviewObject(1)];
    }
    if (isEmptyArray(response.groupSimulations)) {
        response.groupSimulations = [getNewGroupSimulationObject(1)];
    }
    if (isEmptyArray(response.individualSimulations)) {
        response.individualSimulations = [getNewIndividualSimulationObject(1)];
    }
    if (isEmptyArray(response.processAssessments)) {
        response.processAssessments = [];
    }

    return response;
}

export function parenthesizeIfNotEmpty(value) {
    if (isNotBlank(value)) {
        return parenthesize(value);
    }
    return "";
}
