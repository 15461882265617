import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "../components/TextInput";
import { SharedLinkCredentials } from "./SharedLinkCredentials";
import { isNotBlank } from "../utils/uiUtils";
import unionBy from "lodash.unionby";

const buildCredentialsData = (applicants, assessmentData) => {
  return applicants
    .map((applicant) => {
      const candidateData = assessmentData?.find(
        (item) => item.candidateId === applicant.candidateId
      );
      return {
        candidateId: applicant.candidateId,
        url: candidateData?.url,
        userName: candidateData?.userName,
        password: candidateData?.password,
      };
    })
    .filter((item) => item.candidateId);
};

const updateProviderData = (
  providerId,
  providerAssessments,
  url,
  credentialsData,
  updateAction
) => {
  const providerData = credentialsData.map((item) => ({ ...item, url: url }));

  providerAssessments.forEach((assessment) => {
    if (assessment.assessmentId) {
      providerData.forEach((item) => {
        updateAction(item, providerId, assessment.assessmentId);
      });
    }
  });
};

export const SharedLink = ({
  provider,
  applicants,
  updateAction,
  formData,
  disabled,
}) => {
  const { t } = useTranslation();

  const providerId = provider.id;
  const providerAssessments = formData.filter(
    (item) => item.providerId === providerId && item.value === true
  );
  const existingData = providerAssessments.find(
    (item) => item.assessmentData.length > 0
  );

  const [credentialsData, setCredentialsData] = useState(
    buildCredentialsData(applicants, existingData?.assessmentData)
  );

  const providerLinkBase = () => {
    return isNotBlank(provider.linkBase) ? provider.linkBase : "";
  }

  const [url, setUrl] = useState(
    existingData?.assessmentData.find((item) => item.url)?.url ?? providerLinkBase()
  );

  const handleUrlChange = (event) => {
    if (event.currentTarget.value === url) {
      return;
    }
    setUrl(event.currentTarget.value);

    if (credentialsData.length > 0) {
      updateProviderData(
        providerId,
        providerAssessments,
        event.currentTarget.value,
        credentialsData,
        updateAction
      );
    }
  };

  const handleCredentialsChange = (item) => {
    const updatedData = unionBy([item], credentialsData, "candidateId");
    setCredentialsData(updatedData);
    updateProviderData(
      providerId,
      providerAssessments,
      url,
      updatedData,
      updateAction
    );
  };

  return (
    <>
      {providerAssessments.length > 0 && credentialsData.length > 0 ? (
        <div className="mt-2">
          <TextInput
            id={`shared_url_${providerId}`}
            label={t("Process.TestsAndMethods.NonIntegrated.Link")}
            value={url}
            action={handleUrlChange}
            disabled={disabled}
          />
          {applicants.map((applicant) => (
            <SharedLinkCredentials
              key={`credentials_${providerId}_${applicant.candidateId}`}
              providerId={providerId}
              assessmentData={credentialsData}
              applicant={applicant}
              updateAction={handleCredentialsChange}
              disabled={disabled}
            />
          ))}
        </div>
      ) : null}
    </>
  );
};
