import { Transition } from "@headlessui/react";
import { Fragment } from "react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { isEmptyJson, toTitleCase } from "../utils/uiUtils";
import { useTranslation } from "react-i18next";

export const Notification = ({ title, content, show, action, isSuccess = false, errors }) => {
    const { t } = useTranslation();

    const getPropertyTranslation = (key) => {
        const basePart = key.split('[')[0];
        return t(`Process.Properties.${toTitleCase(basePart)}`, key);
    }

    return (
        <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="p-4">
                    <div className="flex items-start">
                        <div className="flex-shrink-0">
                            { isSuccess && <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" /> }
                            { !isSuccess &&  <ExclamationTriangleIcon className="h-6 w-6 text-red-400" aria-hidden="true" /> }
                        </div>
                        <div className="ml-3 w-0 flex-1 pt-0.5">
                            <p className="text-sm font-medium text-gray-900">{ title }</p>
                            <p className="mt-1 text-sm text-gray-500">{ content }</p>
                            { errors && !isEmptyJson(errors) && Object.keys(errors).map((item, index) => (
                                <ul key={ `save_errors_${index}` }>
                                    <li className="mt-1 ml-4 text-sm text-gray-500 list-disc">{ getPropertyTranslation(item) }: { errors[item] }</li>
                                </ul>
                            ))}
                        </div>
                        <div className="ml-4 flex flex-shrink-0">
                            <button
                                type="button"
                                className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                onClick={ action }
                            >
                                <span className="sr-only">Close</span>
                                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    );
};
