import { classNames } from "../utils/uiUtils";

export function Checkbox({ label, id, name, value, isChecked, changeAction }) {
  return (
    <>
      <input
        type="checkbox"
        id={id}
        name={name}
        value={value ?? ""}
        checked={isChecked}
        onChange={changeAction}
        className={classNames(
          "rounded border-gray-300",
          "mr-1 text-mps-ultramarine focus:ring-mps-ultramarine focus:ring-offset-1 focus:ring-1",
        )}
      />
      <label htmlFor={id} className="font-normal text-gray-900">
        {label}
      </label>
    </>
  );
}
