import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCriteriaTemplates } from "../api";
import { CriteriaCard } from "../components/CriteriaCard";
import { LoadingIndicator } from "../components/LoadingIndicator";
import { classNames, isEmptyArray, isNotBlank, isPositiveOrZero } from "../utils/uiUtils";
import { getErrorForKey, getItemPositionByOrdinal } from "./ProcessUtils";
import { CustomCriteriaCard } from "../components/CustomCriteriaCard";
import { Button } from "../components/Button";
import CriteriaFilter from "../components/CriteriaFilter";

export function CriteriaForm({ formData, errors, addCriteriaAction, updateCriteriaAction, validateAction, clearErrorsAction, archived }) {
    const { t } = useTranslation();
    const [criteriaTemplatesLoading, setCriteriaTemplatesLoading] = useState(true);
    const [criteriaTemplates, setCriteriaTemplates] = useState([]);
    const [visibleCriteriaTemplates, setVisibleCriteriaTemplates] = useState([]);
    const [visibleCustomCriteria, setVisibleCustomCriteria] = useState([]);
    const [filterTerm, setFilterTerm] = useState('');

    const sectionKeyCustomCriteria = "customCriteria";

    useEffect( () => {
        const fetchData = async () => {
            await getCriteriaTemplates().then((response) => {
                setCriteriaTemplates(response);
            }).catch((error) => {
                console.log(error);
                setCriteriaTemplates([]);
            }).finally(() => {
                setCriteriaTemplatesLoading(false);
            })
        }
        if (criteriaTemplatesLoading) {
            fetchData().catch(console.error);
        }
    }, [criteriaTemplates, criteriaTemplatesLoading]);

    const updateVisibleCriteria = (templateIds, customCriteriaIds, term) => {
        setVisibleCriteriaTemplates(templateIds);
        setVisibleCustomCriteria(customCriteriaIds);
        setFilterTerm(term);
    }

    const criterionTemplateVisible = (templateId) => {
        return noFilteringApplied() || visibleCriteriaTemplates.includes(templateId);
    }

    const customCriterionVisible = (ordinal) => {
        return noFilteringApplied() || visibleCustomCriteria.includes(ordinal);
    }

    const noFilteringApplied = () => {
        return isEmptyArray(visibleCriteriaTemplates) && isEmptyArray(visibleCustomCriteria);
    }

    const isChecked = (id) => {
        return formData.criteria.some(c => c.criteriaTemplateId === id);
    }

    const criterionContent = (id, defaultContent) => {
        if (!isChecked(id)) {
            return defaultContent;
        }

        let c = formData.criteria.find(c => c.criteriaTemplateId === id);
        return c.customContent || defaultContent;
    }

    const criterionModified = (id, defaultContent) => {
        if (!isChecked(id)) {
            return false;
        }

        let c = formData.criteria.find(c => c.criteriaTemplateId === id);
        return isNotBlank(c.customContent) && (c.customContent !== defaultContent);
    }

    const getError = (key, templateId) => {
        let position = formData.criteria.findIndex(
            entry => entry.criteriaTemplateId === templateId
        );

        let errorKey = getFieldId(key, position);
        return getErrorForKey(errors, errorKey);
    }

    const getCustomCriteriaError = (ordinal, fieldSuffix) => {
        let position = getCustomCriteriaPosition(ordinal);
        let errorKey = `${getCustomCriteriaFieldId(position)}.${fieldSuffix}`;
        return getErrorForKey(errors, errorKey);
    }

    const addNewFieldToCustomCriteria = () => {
        const maxOrdinal= isEmptyArray(formData.customCriteria) ? 0 : Math.max(...formData.customCriteria.map(item => item.ordinal));
        const newItem = {
            ordinal: maxOrdinal + 1,
            title: '',
            content: ''
        };
        addCriteriaAction("customCriteria", newItem);
    };
    const removeCustomCriteria = (e, ordinal) => {
        let position = getCustomCriteriaPosition(ordinal);
        let updatedItems = formData.customCriteria.filter(item => item.ordinal !== ordinal);
        updateCriteriaAction(sectionKeyCustomCriteria, updatedItems);
        if (position >= 0) {
            clearErrorsAction(sectionKeyCustomCriteria, position);
        }
    }

    const modifyCustomCriteriaContent = (key, ordinal, title, content) => {
        const updatedData = formData.customCriteria.map((item) => (item.ordinal === ordinal ? { ...item, title: title, content: content } : item));
        updateCriteriaAction(sectionKeyCustomCriteria, updatedData);

        let position = getCustomCriteriaPosition(ordinal);
        validateAction(`${key}[${position}].title`, title);
        validateAction(`${key}[${position}].content`, content);
    }

    const modifyCriteriaContent = (id, value) => {
        if (criteriaExists(id)) {
            let existingItem = formData.criteria.find(c => c.criteriaTemplateId === id);
            existingItem["customContent"] = value;
        } else {
            const newItem = {
                criteriaTemplateId: id,
                customContent: value
            };
            addCriteriaAction("criteria", newItem);
        }
        let position = getCriteriaPosition(id);
        if (isPositiveOrZero(position)) {
            validateAction( `criteria[${position}].customContent`, value);
        }
    }

    const updateCriteria = (e, id) => {
        if (criteriaExists(id)) {
            let keepItems = formData.criteria.filter(item => item.criteriaTemplateId !== id);
            updateCriteriaAction("criteria", keepItems);
        } else {
            const newItem = {
                criteriaTemplateId: id
            };
            addCriteriaAction("criteria", newItem);
        }
    };

    const getCustomCriteriaFieldId = (index) => {
        return `customCriteria[${index}]`;
    }

    const getFieldId = (key, index) => {
        return `${key}[${index}].customContent`;
    }

    const getCriteriaPosition = (id) => {
        return formData.criteria.findIndex(
            entry => entry.criteriaTemplateId === id
        );
    }

    const criteriaExists = (id) => {
        return formData.criteria.some(c => c.criteriaTemplateId === id);
    }

    const getCustomCriteriaPosition = (ordinal) => {
        return getItemPositionByOrdinal(formData.customCriteria, ordinal);
    }

    return (
        <>
            { criteriaTemplatesLoading &&  (
                <div>
                    <LoadingIndicator type="small"/> <span className="pl-3">{ t("Overlay.Loading") }</span>
                </div>
            )}
            <div className="w-full text-base flex items-center justify-between">
                <div className="text-left">
                    <CriteriaFilter
                        label={ t("Process.Criteria.Filter.Title") }
                        placeholder={ t("Process.Criteria.Filter.Placeholder") }
                        criteriaTemplates={ criteriaTemplates }
                        customCriteria={ formData.customCriteria }
                        templateFilterAction={ updateVisibleCriteria }
                    />
                </div>
                <div className="flex items-center space-x-4 pt-7">
                    <Button
                        text={ t("Process.Criteria.CustomCriteria.Add") }
                        icon={ "PlusIcon" }
                        type="secondary"
                        action={ addNewFieldToCustomCriteria }
                        isDisabled={ archived }
                    />
                </div>
            </div>
            { formData.customCriteria && formData.customCriteria.length > 0 && (
                <div>
                    <div className="py-5">
                        <h3 className="text-2xl font-semibold leading-6 text-gray-900">{t("Process.Criteria.CustomCriteria.Title")}</h3>
                    </div>
                    <ul className="grid w-full gap-6 md:grid-cols-2 items-stretch place-content-stretch">
                        { formData.customCriteria.map((item, index) => (
                            <li key={ `customCriteria.${item.ordinal}` }  className={classNames(
                                customCriterionVisible(item.ordinal) ? "" : "hidden"
                            )}>
                                <CustomCriteriaCard
                                    id={ getCustomCriteriaFieldId(index) }
                                    title={ item.title }
                                    content={ item.content }
                                    ordinal={ item.ordinal }
                                    removeAction={ (event) => removeCustomCriteria(event, item.ordinal) }
                                    titleError={ getCustomCriteriaError(item.ordinal, "title") }
                                    contentError={ getCustomCriteriaError(item.ordinal, "content") }
                                    onEditConfirmedAction={ modifyCustomCriteriaContent }
                                    filterTerm={ filterTerm }
                                    disabled={ archived }
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            { criteriaTemplates.length > 0 && criteriaTemplates.map((item, index) => (
                <div key={ item.category }>
                    <div className="py-5">
                        <h3 className="text-2xl font-semibold leading-6 text-gray-900">{ item.category }</h3>
                    </div>
                    <ul className="grid w-full gap-6 md:grid-cols-2 items-stretch place-content-stretch">
                        { item["templates"].map((template) => (
                            <li key={ template.id } className={classNames(
                                criterionTemplateVisible(template.id) ? "" : "hidden"
                            )}>
                                <CriteriaCard
                                    id={ getFieldId("criteria", template.id) }
                                    value={ template.id }
                                    title={ template.title }
                                    content={ criterionContent(template.id, template.content) }
                                    isChecked={ isChecked(template.id) }
                                    action={ updateCriteria }
                                    onEditConfirmedAction={ modifyCriteriaContent }
                                    isModified={ criterionModified(template.id, template.content) }
                                    error={ getError("criteria", template.id) }
                                    filterTerm={ filterTerm }
                                    position={ index }
                                    disabled={ archived }
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </>
    );
}
