import { useEffect, useState } from "react";
import { getOwnProcesses } from "../api";
import { useTranslation } from "react-i18next";
import { LoadingIndicator } from "../components/LoadingIndicator";
import { ProcessesListView } from "./components/ProcessesListView";

export function ProcessList({ state }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [processes, setProcesses] = useState([]);

  const titleTranslationKey = `Processes.State.${state}`;
  const noProcessesTranslationKey = `Processes.NoProcesses.${state}`;

  useEffect(() => {
    const states = ["Draft", "Published"];
    const fetchData = async () => {
      await getOwnProcesses(state)
        .then((response) => {
          setProcesses(response);
        })
        .catch(() => {
          setProcesses([]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    if (isLoading && states.includes(state)) {
      fetchData().catch(console.error);
    }
  }, [processes, isLoading, state]);

  return (
    <div className="space-y-2 mt-6 w-full">
      <h2 className="text-lg font-semibold leading-7 text-gray-900">
        {t(titleTranslationKey)}
      </h2>

      {processes.length > 0 && <ProcessesListView processes={processes} />}
      {processes.length === 0 && !isLoading && (
        <div className="leading-7 text-gray-700 text-sm">
          {t(noProcessesTranslationKey)}
        </div>
      )}
      {isLoading && (
        <div>
          <LoadingIndicator type="small" />{" "}
          <span className="pl-3">{t("Overlay.Loading")}</span>
        </div>
      )}
    </div>
  );
}
