import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { TextArea } from "../components/TextArea";
import { InterviewType } from "../components/InterviewType";
import { ToggleOnLeft } from "../components/Toggle";
import { Timeframe } from "./components/Timeframe";
import { getFieldError } from "./ProcessUtils";

export const GroupSimulationCard = ({ position, item, updateAction, errors, disabled }) => {
    const { t } = useTranslation();

    const fieldPrefix = `groupSimulations[${position}]`;

    const handleChange = (event, fieldSuffix) => {
        updateAction(item.ordinal, fieldSuffix, event.target.value);
    }

    const getError = (fieldSuffix) => {
        return getFieldError(errors, fieldPrefix, fieldSuffix);
    }

    const handleToggle = (value) => {
        updateAction(item.ordinal, "language", value ? "en" : "");
    }

    return (
        <>
            <div className="col-span-full">
                <InterviewType
                    label={ t("Process.Properties.GroupInterviewType") }
                    item={ item }
                    fieldPrefix={ fieldPrefix }
                    updateAction={ (event) => handleChange(event,"interviewType") }
                    includeNoInterview={ true }
                    noInterviewLabel={ t("Process.Properties.GroupSimulationNone") }
                    disabled={ disabled }
                />
            </div>
            <div className="col-span-full">
                <ToggleOnLeft label={t("Process.Interviews.Section2.Language")} value={item.language ?? false} action={handleToggle} disabled={disabled}/>
            </div>

            <div className="col-span-full">
                <TextArea
                    label={ t("Process.Properties.GroupInterviewNotes") }
                    id={ `${fieldPrefix}.notes` }
                    name={ `${fieldPrefix}.notes` }
                    value={ item.notes }
                    action={ (event) => handleChange(event,"notes") }
                    rows={ 4 }
                    error={ getError("notes") }
                    disabled={ disabled }
                />
            </div>

            <div className="col-span-full">
                <fieldset className="w-full">
                    <Timeframe
                        title={t("Process.Properties.GroupSimulationTimeframe")}
                        fieldPrefix={fieldPrefix}
                        updateAction={handleChange}
                        startDate={item.timeframeStart}
                        endDate={item.timeframeEnd}
                        startError={getError("timeframeStart")}
                        endError={getError("timeframeEnd")}
                        disabled={disabled}
                    />
                </fieldset>
            </div>
        </>
    );
};
