import { LogLevel } from "msal";

export const msalConfig = {
    auth: {
        clientId: `${process.env.REACT_APP_AUTH_CLIENT_ID}`,
        authority: `${process.env.REACT_APP_AUTH_AUTHORITY}`,
        redirectUri: `${process.env.REACT_APP_UI_ADDRESS}/redirect`,
        postLogoutRedirectUri: `${process.env.REACT_APP_UI_ADDRESS}`,
        mainWindowRedirectUri: `${process.env.REACT_APP_UI_ADDRESS}`,
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};

/**
 * Scopes defined here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 */
export const loginRequest = {
    scopes: [
        'email',
        'profile',
        'openid',
        'User.Read',
        process.env.REACT_APP_API_TOKEN_SCOPE
    ]
};
