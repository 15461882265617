import React from "react";
import { loginRequest } from "./msal/MsalConfig";

const UserContext = React.createContext(null);

const adminOrCoordinatorRoles = [process.env.REACT_APP_ROLE_ADMIN, process.env.REACT_APP_ROLE_COORDINATOR];
const allTalentHubRoles = adminOrCoordinatorRoles + [process.env.REACT_APP_ROLE_CONSULTANT];

export const UserContextProvider = ({instance, children}) => {
    const userRoles = instance.getActiveAccount()?.idTokenClaims.roles || [];

    const hasAccess = (allowedRoles) => {
        return !allowedRoles || userRoles.some((role) => allowedRoles.includes(role));
    };

    const hasAdminOrCoordinatorRole = () => {
        const userRoles = instance.getActiveAccount()?.idTokenClaims.roles;
        return userRoles.some(role => adminOrCoordinatorRoles.includes(role));
    }

    const hasTalentHubRole = () => {
        const userRoles = instance.getActiveAccount()?.idTokenClaims.roles;
        return userRoles.some(role => allTalentHubRoles.includes(role));
    }

    const handleLoginRedirect = () => {
        instance.loginRedirect(loginRequest).catch((error) => console.log(error));
    };

    const handleLogoutRedirect = () => {
        instance.logoutRedirect().catch((error) => console.log(error));
    };

    const value = {
        userRoles: userRoles,
        hasAccess: hasAccess,
        hasAdminOrCoordinatorRole: hasAdminOrCoordinatorRole,
        hasTalentHubRole: hasTalentHubRole,
        loginRedirect: handleLoginRedirect,
        logoutRedirect: handleLogoutRedirect
    };

    return (
        <UserContext.Provider value={ value }>
            {children}
        </UserContext.Provider>
    );
};

export const useUserContext = () => {
    return React.useContext(UserContext);
};
