import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { FormSection } from "./FormSection";
import { IndividualInterviewCard } from "./IndividualInterviewCard";
import { GroupSimulationCard } from "./GroupSimulationCard";
import { IndividualSimulationCard } from "./IndividualSimulationCard";
import { getItemPositionByOrdinal } from "./ProcessUtils";
import { isPositiveOrZero } from "../utils/uiUtils";

export function InterviewsForm({ formData, errors, updateAction, validateAction, archived }) {
    const { t } = useTranslation();

    const individualInterviewsSectionKey = "individualInterviews";
    const individualSimulationsSectionKey = "individualSimulations";
    const groupSimulationsSectionKey = "groupSimulations";

    const updateIndividualInterviews = (ordinal, fieldSuffix, value) => {
        updateRecruitmentTests(individualInterviewsSectionKey, ordinal, fieldSuffix, value);
    }

    const updateGroupSimulations = (ordinal, fieldSuffix, value) => {
        updateRecruitmentTests(groupSimulationsSectionKey, ordinal, fieldSuffix, value);
    }

    const updateIndividualSimulations = (ordinal, fieldSuffix, value) => {
        updateRecruitmentTests(individualSimulationsSectionKey, ordinal, fieldSuffix, value);
    }

    const updateRecruitmentTests = (sectionKey, ordinal, fieldSuffix, value) => {
        const updatedData = formData[sectionKey]?.map((item) => (item.ordinal === ordinal ?
            { ...item,
                [fieldSuffix]: value
            } : item));

        updateAction(sectionKey, updatedData);

        let position = getItemPositionByOrdinal(updatedData, ordinal);

        if (isPositiveOrZero(position)) {
            validateAction(`${sectionKey}[${position}].${fieldSuffix}`, value);
        }
    }

    return (
        <>
            <FormSection title={t("Process.Interviews.Section1.Title")} summary={t("Process.Interviews.Section1.Summary")}>
                <div className="grid max-w-3xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                    { formData.individualInterviews && formData.individualInterviews.length > 0 && formData.individualInterviews.map((item, index) => (
                        <Fragment key={ `individualInterviews_key_${index}` }>
                            <IndividualInterviewCard
                                position={ index }
                                updateAction={ updateIndividualInterviews }
                                item={ item }
                                errors={ errors }
                                disabled={ archived }
                            />
                        </Fragment>
                    ))}
                </div>
            </FormSection>


            <FormSection title={t("Process.Interviews.Section2.Title")} summary={t("Process.Interviews.Section2.Summary")}>
                <div className="grid max-w-3xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                    { formData.groupSimulations && formData.groupSimulations.length > 0 && formData.groupSimulations.map((item, index) => (
                        <Fragment key={ `groupSimulations_key_${index}` }>
                            <GroupSimulationCard
                                position={ index }
                                updateAction={ updateGroupSimulations }
                                item={ item }
                                errors={ errors }
                                disabled={ archived }
                            />
                        </Fragment>
                    ))}
                </div>
            </FormSection>

            <FormSection title={t("Process.Interviews.Section3.Title")} summary={t("Process.Interviews.Section3.Summary")}>
                <div className="grid max-w-3xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                    { formData.individualSimulations && formData.individualSimulations.length > 0 && formData.individualSimulations.map((item, index) => (
                        <Fragment key={ `individualSimulations_key_${index}` }>
                            <IndividualSimulationCard
                                position={ index }
                                updateAction={ updateIndividualSimulations }
                                item={ item }
                                errors={ errors }
                                disabled={ archived }
                            />
                        </Fragment>
                    ))}
                </div>
            </FormSection>
        </>
    );
}
