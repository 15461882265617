import { classNames } from "../utils/uiUtils";
import { Link } from "react-router-dom";
import { ArrowLongLeftIcon, ArrowLongRightIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

export default function TabsFooter({ tabs, activeTab, action }) {
  const { t } = useTranslation();
  const nextIndex = activeTab + 1;
  const prevIndex = activeTab - 1;
  const handleTabClickEvent = (e, id) => {
    e.preventDefault();
    action(id);
  };

  const smallestIndex = tabs.reduce((min, tab) => {
    return tab.index < min ? tab.index : min;
  }, tabs[0].index);

  const largestIndex = tabs.reduce((max, tab) => {
    return tab.index > max ? tab.index : max;
  }, tabs[0].index);

  const prevButtonVisible = activeTab > smallestIndex;
  const nextButtonVisible = activeTab < largestIndex;

  const prevLabel = () => {
    let tab = tabs.find((tab) => tab.index === prevIndex);
    return tab.name || t("Action.Previous");
  };

  const nextLabel = () => {
    let tab = tabs.find((tab) => tab.index === nextIndex);
    return tab.name || t("Action.Next");
  };

  return (
    <div
      className={classNames(
        prevButtonVisible ? "justify-between" : "justify-end",
        "flex items-center",
      )}
    >
      { prevButtonVisible && (
        <Link
          to={`/`}
          onClick={(event) => handleTabClickEvent(event, prevIndex)}
          className="font-normal text-sm text-mps-ultramarine dark:text-mps-ultramarine inline-flex items-center">
          <ArrowLongLeftIcon className="mr-1 h-5 w-5" /> { prevLabel() }
        </Link>
      )}

      { nextButtonVisible && (
        <Link
          to={`/`}
          onClick={(event) => handleTabClickEvent(event, nextIndex)}
          className="font-normal text-sm text-mps-ultramarine dark:text-mps-ultramarine  inline-flex items-center">
          { nextLabel() }<ArrowLongRightIcon className="ml-1 h-5 w-5" />
        </Link>
      )}
    </div>
  );
}
