import DatePicker from "react-datepicker";
import {
  classNames,
  formatAsIsoDate,
  formatDateAsIsoDate,
  isNotBlank,
} from "../utils/uiUtils";
import { fi } from "date-fns/locale";
import { textInputClasses, textInputCommonClasses } from "./ComponentStyles";

export function DatePickerField({
  id,
  name,
  value,
  placeholder,
  error,
  updateAction,
  comboField = false,
  disabled = false
}) {
  const dateValue = isNotBlank(value) ? formatAsIsoDate(value) : "";

  const handleDateChange = (date) => {
    let event = {
      target: {
        name: id,
        value: formatDateAsIsoDate(date),
      },
    };
    updateAction(event);
  };

  return (
    <DatePicker
      id={id}
      name={name}
      selected={dateValue}
      locale={fi}
      onChange={handleDateChange}
      placeholderText={placeholder}
      dateFormat="dd.MM.yyyy"
      autoComplete="off"
      className={classNames(
        error
          ? "text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500"
          : "text-gray-900 ring-gray-300 placeholder:text-gray-400 focus:ring-mps-ultramarine",
        comboField
          ? "relative block w-full rounded-none rounded-tl-md rounded-bl-md border-0 bg-transparent py-1.5 ring-1 ring-inset focus:z-10 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
          : textInputClasses + textInputCommonClasses,
        "w-24",
      )}
      disabled={ disabled }
    />
  );
}
