import { useTranslation } from "react-i18next";
import { RadioButton } from "./RadioButton";
import { Required } from "./Required";
import { InputErrorMessage } from "./InputErrorMessage";
import { classNames } from "../utils/uiUtils";

export const InterviewType = ({
  label,
  fieldPrefix,
  item,
  updateAction,
  required = false,
  error,
  includeNoInterview = false,
  includeIndividualSimulation = false,
  noInterviewLabel,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <fieldset className="w-full">
      <legend className="text-sm font-semibold leading-6 text-gray-900">
        {label}
        {required && required === true && <Required />}
      </legend>
      <div className={classNames("mt-2 flex flex-row flex-wrap columns-2xl")}>
        <RadioButton
          label={t("Process.Properties.Teams")}
          id={`${fieldPrefix}.interviewType.teams`}
          name={`${fieldPrefix}.interviewType`}
          value="Teams"
          action={updateAction}
          checked={item.interviewType === "Teams"}
          disabled={disabled}
        />
        <RadioButton
          label={t("Process.Properties.Office")}
          id={`${fieldPrefix}.interviewType.office`}
          name={`${fieldPrefix}.interviewType`}
          value="Office"
          action={updateAction}
          checked={item.interviewType === "Office"}
          disabled={disabled}
        />
        {includeIndividualSimulation && (
          <RadioButton
            label={t("Process.Properties.IndividualSimulation")}
            id={`${fieldPrefix}.interviewType.individualSimulation`}
            name={`${fieldPrefix}.interviewType`}
            value="IndividualSimulation"
            action={updateAction}
            checked={item.interviewType === "IndividualSimulation"}
            disabled={disabled}
          />
        )}
        {includeNoInterview && (
          <RadioButton
            label={noInterviewLabel}
            id={`${fieldPrefix}.interviewType.noInterview`}
            name={`${fieldPrefix}.interviewType`}
            value="NoInterview"
            action={updateAction}
            checked={item.interviewType === "NoInterview"}
            disabled={disabled}
          />
        )}
      </div>
      <InputErrorMessage id={`${fieldPrefix}-error`} error={error} />
    </fieldset>
  );
};
