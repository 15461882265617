import { msalInstance } from "./index";
import { msalConfig } from './msal/MsalConfig';
import { apiRequest } from './config/ApiConfig';
import { addClaimsToStorage, getClaimsFromStorage, callEndpoint } from "./utils/storageUtils";
import { parseChallenges } from "./utils/claimUtils";
import { objectToQueryString } from "./utils/uiUtils";

const getToken = async (endpoint, method) => {
    const account = msalInstance.getActiveAccount();

    if (!account) {
        throw Error("Verify a user has been signed in!");
    }

    /**
     * Create a TokenRequest object to retrieve an access token silently from the cache via acquireTokenSilent
     */
    const tokenRequest = {
        account: account,
        scopes: [process.env.REACT_APP_API_TOKEN_SCOPE],
        claims: getClaimsFromStorage(`cc.${msalConfig.auth.clientId}.${account.idTokenClaims.oid}.${new URL(endpoint).hostname}.${method}`) ?
            window.atob(getClaimsFromStorage(`cc.${msalConfig.auth.clientId}.${account.idTokenClaims.oid}.${new URL(endpoint).hostname}.${method}`)) : null
    }

    const response = await msalInstance.acquireTokenSilent(tokenRequest);
    return response.accessToken;
}

const handleResponse = async (response, endpoint, options, id = '') => {
    if (response.status === 200 || response.status === 201) {
        return response.json();
    } else if (response.status === 400 || response.status === 500) {
        return response.json();
    } else if (response.status === 401) {
        if (response.headers.get('www-authenticate')) {
            return handleClaimsChallenge(response, endpoint, options, id);
        }
        throw new Error(`Unauthorized: ${response.status}`);
    } else if (response.status === 403) {
        window.location.replace("/accessDenied");
    } else {
        throw new Error(`Something went wrong with the request: ${response.status}`);
    }
}

const handleDeleteResponse = async (response) => {
    if (response.status === 200) {
        return response;
    } else if (response.status === 403) {
        window.location.replace("/accessDenied");
    } else {
        throw new Error(`Something went wrong with the request: ${response.status}`);
    }
}

const handleGetResponseOk = async (response) => {
    if (response.status === 200) {
        return await response.json();
    } else if (response.status === 400 || response.status === 500) {
        return response.json();
    } else if (response.status === 403) {
        window.location.replace("/accessDenied");
    } else {
        throw new Error(`Something went wrong with the request: ${response.status}`);
    }
}

const handleUserInitResponse = async (response) => {
    return response;
}

const handleClaimsChallenge = async (response, endpoint, options, id = '') => {
    const account = msalInstance.getActiveAccount();

    const authenticateHeader = response.headers.get('www-authenticate');
    const claimsChallenge = parseChallenges(authenticateHeader);

    addClaimsToStorage(
        `cc.${msalConfig.auth.clientId}.${account.idTokenClaims.oid}.${new URL(endpoint).hostname}.${options.method}`,
        claimsChallenge.claims
    );

    try {
        const tokenResponse = await msalInstance.acquireTokenPopup({
            claims: window.atob(claimsChallenge.claims),
            scopes: [process.env.REACT_APP_API_TOKEN_SCOPE],
            redirectUri: '/redirect',
        });

        if (tokenResponse.accessToken) {
            return callEndpoint(options, id);
        }

    } catch (error) {
        console.error(error);
    }
}

const getHeaders = async (endpoint, method, includeContentType = true) => {
    const accessToken = await getToken(endpoint, method);

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    if (includeContentType) {
        headers.append('Content-Type', 'application/json');
    }

    return headers;
}

export const initUser = async () => {
    const method = "POST"
    const endpoint = apiRequest.apiUsers.usersAdminEndpoint;
    const headers = await getHeaders(endpoint, method);

    const options = {
        method,
        headers: headers
    };

    return await fetch(endpoint, options)
        .then((res) => handleUserInitResponse(res, endpoint, options));
}

export const saveProcess = async (process, publish = false, silentMode = false) => {
    if (process.type === 'CreateProcess') {
        return await postProcess(process);
    } else if (publish) {
        return await publishProcess(process);
    } else {
        return await editProcess(process, silentMode);
    }
}

export const postProcess = async (process) => {
    const method = "POST"
    const endpoint = apiRequest.apiProcesses.processesAdminEndpoint;
    const headers = await getHeaders(endpoint, method);

    const options = {
        method,
        headers: headers,
        body: JSON.stringify(process)
    };

    return await fetch(endpoint, options)
        .then((res) => handleResponse(res, endpoint, options));
}

export const editProcess = async (process, silentMode) => {
    const method = "PUT"
    const endpoint = apiRequest.apiProcesses.processesAdminEndpoint;
    const headers = await getHeaders(endpoint, method);

    const options = {
        method,
        headers: headers,
        body: JSON.stringify(process)
    };

    return await fetch(`${endpoint}/${process.id}?sm=${encodeURIComponent(silentMode)}`, options)
        .then((res) => handleResponse(res, endpoint, options, process?.id));
}

export const publishProcess = async (process) => {
    const method = "PUT"
    const endpoint = apiRequest.apiProcesses.processesAdminEndpoint;
    const headers = await getHeaders(endpoint, method);

    const options = {
        method,
        headers: headers,
        body: JSON.stringify(process)
    };

    return await fetch(`${endpoint}/${process.id}/publish`, options)
        .then((res) => handleResponse(res, endpoint, options, process?.id));
}

export const archiveProcess = async (processId) => {
    const method = "PUT"
    const endpoint = apiRequest.apiProcesses.processesAdminEndpoint;
    const headers = await getHeaders(endpoint, method);

    const options = {
        method,
        headers: headers
    };

    return await fetch(`${endpoint}/${processId}/archive`, options)
        .then((res) => handleResponse(res, endpoint, options, processId));
}

export const deleteProcess = async (processId) => {
    const method = "DELETE"
    const endpoint = apiRequest.apiProcesses.processesAdminEndpoint;
    const headers = await getHeaders(endpoint, method);

    const options = {
        method,
        headers: headers
    };

    return await fetch(`${endpoint}/${processId}`, options)
        .then((res) => handleDeleteResponse(res));
}

export const getProcessById = async (id) => {
    const method = "GET"
    const endpoint = apiRequest.apiProcesses.processesAdminEndpoint;
    const headers = await getHeaders(endpoint, method);

    const options = {
        method,
        headers: headers
    };

    return await fetch(`${endpoint}/${id}`, options)
        .then((res) => handleGetResponseOk(res));
}

export const getProcessStatus = async (id) => {
    const method = "GET"
    const endpoint = apiRequest.apiProcesses.processesAdminEndpoint;
    const headers = await getHeaders(endpoint, method);

    const options = {
        method,
        headers: headers
    };

    return await fetch(`${endpoint}/${id}/status`, options)
        .then((res) => handleGetResponseOk(res));
}

export const getProcessApplicantStatus = async (id, applicantId) => {
    const method = "GET"
    const endpoint = apiRequest.apiProcesses.processesAdminEndpoint;
    const headers = await getHeaders(endpoint, method);

    const options = {
        method,
        headers: headers
    };

    return await fetch(`${endpoint}/${id}/applicant/${applicantId}/status`, options)
        .then((res) => handleGetResponseOk(res));
}

export const resendApplicantInvitationMail = async (id, candidateId) => {
    const method = "POST"
    const endpoint = apiRequest.apiProcesses.processesAdminEndpoint;
    const headers = await getHeaders(endpoint, method);

    const options = {
        method,
        headers: headers
    };

    return await fetch(`${endpoint}/${id}/applicant/${candidateId}/invitationMail/send`, options)
        .then((res) => handleGetResponseOk(res));
}

export const getProcessInvitationMailsByServiceType = async (serviceType) => {
    const method = "GET"
    const endpoint = apiRequest.apiProcesses.processesAdminEndpoint;
    const headers = await getHeaders(endpoint, method);

    const options = {
        method,
        headers: headers
    };

    return await fetch(`${endpoint}/invitationMails?serviceType=${encodeURIComponent(serviceType)}`, options)
        .then((res) => handleGetResponseOk(res));
}

export const updateApplicantAssessmentStatus = async (id, assessmentId, candidateId, state) => {
    const method = "PUT"
    const endpoint = apiRequest.apiProcesses.processesAdminEndpoint;
    const headers = await getHeaders(endpoint, method);

    const options = {
        method,
        headers: headers
    };

    return await fetch(`${endpoint}/${id}/assessment/${assessmentId}?candidateId=${candidateId}&state=${state}`, options)
        .then((res) => handleGetResponseOk(res));
}

export const getOwnProcesses = async (state) => {
    const method = "GET"
    const endpoint = apiRequest.apiProcesses.processesAdminEndpoint;
    const headers = await getHeaders(endpoint, method);

    const options = {
        method,
        headers: headers
    };

    return await fetch(`${endpoint}?state=${encodeURIComponent(state)}`, options)
        .then((res) => handleGetResponseOk(res));
}

export const getProcessChangeHistoryById = async (id) => {
    const method = "GET"
    const endpoint = apiRequest.apiChangeHistory.processesChangeHistoryAdminEndpoint;
    const headers = await getHeaders(endpoint, method);

    const options = {
        method,
        headers: headers
    };

    return await fetch(`${endpoint}/${id}`, options)
        .then((res) => handleGetResponseOk(res));
}

export const searchCustomerByName = async (customerName) => {
    const method = "GET"
    const endpoint = apiRequest.apiCustomers.customersAdminSearchEndpoint;
    const headers = await getHeaders(endpoint, method);

    const options = {
        method,
        headers: headers
    };

    return await fetch(`${endpoint}?name=${encodeURIComponent(customerName)}`, options)
        .then((res) => handleGetResponseOk(res));
}

export const searchEmail = async (partialEmail) => {
    const method = "GET"
    const endpoint = apiRequest.apiUsers.searchUsersAdminEndpoint;
    const headers = await getHeaders(endpoint, method);

    const options = {
        method,
        headers: headers
    };

    return await fetch(`${endpoint}?email=${encodeURIComponent(partialEmail)}`, options)
        .then((res) => handleGetResponseOk(res));
}

export const getCriteriaTemplates = async () => {
    const method = "GET"
    const endpoint = apiRequest.apiCriteriaTemplates.criteriaTemplatesAdminEndpoint;
    const headers = await getHeaders(endpoint, method);

    const options = {
        method,
        headers: headers
    };

    return await fetch(endpoint, options)
        .then((res) => handleGetResponseOk(res));
}

export const getOffices = async () => {
    const method = "GET"
    const endpoint = apiRequest.apiOffices.officesAdminEndpoint;
    const headers = await getHeaders(endpoint, method);

    const options = {
        method,
        headers: headers
    };

    return await fetch(endpoint, options)
        .then((res) => handleGetResponseOk(res));
}

export const getTestProviders = async () => {
    const method = "GET"
    const endpoint = apiRequest.apiAssessments.testProvidersAdminEndpoint;
    const headers = await getHeaders(endpoint, method);

    const options = {
        method,
        headers: headers
    };

    return await fetch(endpoint, options)
        .then((res) => handleGetResponseOk(res));
}

export const getTranslations = async () => {
    const method = "GET"
    const endpoint = apiRequest.apiAssessments.translationsAdminEndpoint;
    const headers = await getHeaders(endpoint, method);

    const options = {
        method,
        headers: headers
    };

    return await fetch(endpoint, options)
        .then((res) => handleGetResponseOk(res));
}

export const saveTranslations = async (translations) => {
    const method = "PUT"
    const endpoint = apiRequest.apiAssessments.translationsAdminEndpoint;
    const headers = await getHeaders(endpoint, method);

    const options = {
        method,
        headers: headers,
        body: JSON.stringify(translations)
    };

    return await fetch(endpoint, options)
        .then((res) => handleGetResponseOk(res));
}

export const getCandidateById = async (candidateId) => {
    const method = "GET"
    const endpoint = apiRequest.apiCandidates.candidatesAdminEndpoint;
    const headers = await getHeaders(endpoint, method);

    const options = {
        method,
        headers: headers
    };

    return await fetch(`${endpoint}/${candidateId}`, options)
        .then((res) => handleGetResponseOk(res));
}

export const getCandidateProcesses = async (candidateId) => {
    const method = "GET"
    const endpoint = apiRequest.apiProcesses.processesAdminEndpoint;
    const headers = await getHeaders(endpoint, method);

    const options = {
        method,
        headers: headers
    };

    return await fetch(`${endpoint}/candidate/${candidateId}`, options)
        .then((res) => handleGetResponseOk(res));
}

export const getCompletedCandidateProcessAssessments = async (candidateId) => {
    const method = "GET"
    const endpoint = apiRequest.apiProcesses.processesAdminEndpoint;
    const headers = await getHeaders(endpoint, method);

    const options = {
        method,
        headers: headers
    };

    return await fetch(`${endpoint}/candidate/${candidateId}/validAssessments`, options)
        .then((res) => handleGetResponseOk(res));
}

export const getCandidateAttachments = async (candidateId) => {
    const method = "GET"
    const endpoint = apiRequest.apiCandidates.candidatesAdminEndpoint;
    const headers = await getHeaders(endpoint, method);

    const options = {
        method,
        headers: headers
    };

    return await fetch(`${endpoint}/${candidateId}/attachments`, options)
        .then((res) => handleGetResponseOk(res));
}

export const searchApplicants = async (searchData) => {
    const method = "GET"
    const endpoint = apiRequest.apiSearch.assessmentSearchEndpoint;
    const headers = await getHeaders(endpoint, method);

    let queryParameters = objectToQueryString(searchData);

    const options = {
        method,
        headers: headers
    };

    return await fetch(`${endpoint}?${queryParameters}`, options)
        .then((res) => handleGetResponseOk(res));
}

export const downloadAssessmentLog = async (searchData) => {
    const method = "GET"
    const endpoint = apiRequest.apiSearch.assessmentLogDownloadEndpoint;
    const headers = await getHeaders(endpoint, method);

    let queryParameters = objectToQueryString(searchData);

    const options = {
        method,
        headers: headers
    };

    return await fetch(`${endpoint}?${queryParameters}`, options)
        .then(res => res.blob() )
        .then(blob => {
            const fileURL = window.URL.createObjectURL(blob);
            const fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.download = `assessmentLog-${searchData.fromDate}_${searchData.toDate}.csv`;
            fileLink.click();
        });
}

export const textSearch = async (searchTerm) => {
    const method = "GET"
    const endpoint = apiRequest.apiSearch.textSearchEndpoint;
    const headers = await getHeaders(endpoint, method);

    const options = {
        method,
        headers: headers
    };
    return await fetch(`${endpoint}?searchTerm=${encodeURIComponent(searchTerm)}`, options)
        .then((res) => handleGetResponseOk(res));
}

export const getPdfDownloadOptions = async (url) => {
    const method = "GET"
    const headers = await getHeaders(url, method, false);

    return {
        method,
        headers: headers,
        responseType: "blob"
    };
}

export const getAvailableInterviewSlots = async (processId, candidateId) => {
    const method = "GET"
    const endpoint = apiRequest.apiBookings.bookingEndpoint;
    const headers = await getHeaders(endpoint, method);

    const options = {
        method,
        headers: headers
    };

    return await fetch(`${endpoint}/${processId}/interview?candidateId=${candidateId}`, options)
        .then((res) => handleGetResponseOk(res));
}

export const createInterviewBooking = async (processId, booking) => {
    const method = "POST"
    const endpoint = apiRequest.apiBookings.bookingEndpoint;
    const headers = await getHeaders(endpoint, method);

    const options = {
        method,
        headers: headers,
        body: JSON.stringify(booking)
    };

    return await fetch(`${endpoint}/${processId}/interview`, options)
        .then((res) => handleResponse(res, endpoint, options))
}

export const removeInterviewBooking = async (processId, booking) => {
    const method = "DELETE"
    const endpoint = apiRequest.apiBookings.bookingEndpoint;
    const headers = await getHeaders(endpoint, method);

    const options = {
        method,
        headers: headers,
        body: JSON.stringify(booking)
    };

    return await fetch(`${endpoint}/${processId}/interview`, options)
        .then((res) => handleResponse(res, endpoint, options))
}

export const postRequest = async (endpoint, payload) => {
    const method = "POST"
    const headers = await getHeaders(endpoint, method);

    const options = {
        method,
        headers: headers,
        body: JSON.stringify(payload)
    };

    return await fetch(endpoint, options)
        .then((res) => handleGetResponseOk(res));
}

export const blobStorageUpload = async (url, file, progressCallback) => {
    const xhr = new XMLHttpRequest();
    return new Promise((resolve) => {
        xhr.upload.addEventListener("progress", (event) => {
            if (event.lengthComputable) {
                progressCallback(((event.loaded / event.total) * 100).toFixed(2));
            }
        });

        xhr.addEventListener("loadend", () => {
            resolve(xhr.readyState === 4 && xhr.status === 201);
        });

        xhr.open("PUT", url, true);
        xhr.setRequestHeader("Content-Type", file.type);
        xhr.setRequestHeader("x-ms-blob-type", "BlockBlob");
        xhr.send(file);
    });
};

export const deleteEvaluationReport = async (processId, applicantId) => {
    const method = "DELETE"
    const endpoint = apiRequest.apiProcesses.processesAdminEndpoint;
    const headers = await getHeaders(endpoint, method);

    const options = {
        method,
        headers: headers
    };

    return await fetch(`${endpoint}/${processId}/applicant/${applicantId}/evaluationReport`, options)
        .then((res) => handleGetResponseOk(res, endpoint, options))
}

export const refreshCandidateStatus = async (id, applicantId) => {
    const method = "GET"
    const endpoint = apiRequest.apiProcesses.processesAdminEndpoint;
    const headers = await getHeaders(endpoint, method);

    const options = {
        method,
        headers: headers,
    };

    return await fetch(`${endpoint}/${id}/applicant/${applicantId}/refresh`, options)
        .then((res) => handleResponse(res, endpoint, options));
}

export const continueTestRequest = async (id, applicantId, assessmentId) => {
    const method = "PUT"
    const endpoint = apiRequest.apiProcesses.processesAdminEndpoint;
    const headers = await getHeaders(endpoint, method);

    const options = {
        method,
        headers: headers
    };

    return await fetch(`${endpoint}/${id}/applicantProcesses/${applicantId}/continueTest/${assessmentId}`, options)
        .then((res) => handleResponse(res, endpoint, options));
}

export const getAvailableGroupSlots = async (processId, candidateId) => {
    const method = "GET"
    const endpoint = apiRequest.apiBookings.bookingEndpoint;
    const headers = await getHeaders(endpoint, method);

    const options = {
        method,
        headers: headers
    };

    return await fetch(`${endpoint}/${processId}/group?candidateId=${candidateId}`, options)
        .then((res) => handleGetResponseOk(res));
}

export const createGroupInterviewBooking = async (processId, booking) => {
    const method = "POST"
    const endpoint = apiRequest.apiBookings.bookingEndpoint;
    const headers = await getHeaders(endpoint, method);

    const options = {
        method,
        headers: headers,
        body: JSON.stringify(booking)
    };

    return await fetch(`${endpoint}/${processId}/group`, options)
        .then((res) => handleResponse(res, endpoint, options))
}

export const removeGroupInterviewBooking = async (processId, booking) => {
    const method = "DELETE"
    const endpoint = apiRequest.apiBookings.bookingEndpoint;
    const headers = await getHeaders(endpoint, method);

    const options = {
        method,
        headers: headers,
        body: JSON.stringify(booking)
    };

    return await fetch(`${endpoint}/${processId}/group`, options)
        .then((res) => handleResponse(res, endpoint, options))
}
