import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SelectBox } from "./SelectBox";
import { DateTime } from "./DateTime";
import { TextInput } from "./TextInput";
import { getFieldError } from "../process/ProcessUtils";
import { HiddenField } from "./HiddenField";
import {
  formatAsLocalDateTime,
  getBookingLabel,
  isBlank,
} from "../utils/uiUtils";
import { Booking } from "./Booking";
import { Checkbox } from "./Checkbox";
import { CheckIcon } from "@heroicons/react/24/solid";
import { resendApplicantInvitationMail } from "../api";
import { LoadingIndicator } from "../components/LoadingIndicator";

export const ApplicantDetails = ({
  formData,
  applicant,
  errors,
  fieldPrefix,
  handleChange,
  handleBookingChange,
  handleInvitationResend,
  offices,
  disabled
}) => {
  const { t } = useTranslation();
  const [isResending, setIsResending] = useState(false);

  const locales = [
    { id: "fi", name: t("Locales.Fi") },
    { id: "sv", name: t("Locales.Sv") },
    { id: "en", name: t("Locales.En") },
  ];
  const getError = (fieldSuffix) => {
    return getFieldError(errors, fieldPrefix, fieldSuffix);
  };

  const selectedOffice = () => {
    if (applicant.officeId) {
      return offices.findIndex((entry) => entry.id === applicant.officeId);
    }
    return 0;
  };

  const selectedLanguage = () => {
    if (applicant.languageCode) {
      return locales.findIndex((entry) => entry.id === applicant.languageCode);
    }
    return 0;
  };

  const handleCheckboxChange = (event) => {
    let newEvent = {
      target: {
        name: event.currentTarget.name,
        value: event.currentTarget.checked,
      },
    };
    handleChange(newEvent);
  };

  const resendInviteButtonClassName = (!disabled && !isResending) ? "text-blue-500 hover:text-blue-800" : "text-gray-500";

  const handleResendInvitationMail = (event) => {
    event.preventDefault();
    setIsResending(true);

    resendApplicantInvitationMail(formData.id, applicant.candidateId)
      .then((response) => {
        handleInvitationResend("invitationSent", response.invitationSent);  
      })
    .catch((error) => {
      console.log(error);
    })
    .finally(() => {
      setIsResending(false);
    });
  };

  return (
    <div className="container mx-auto w-full mt-4 divide-y">
      <div className="pl-4 pr-4">
        <div className="flex mb-4 gap-x-2">
          <div className="w-1/3">
            <HiddenField
              id={`${fieldPrefix}.candidateId`}
              name={`${fieldPrefix}.candidateId`}
              value={applicant.candidateId}
              action={handleChange}
            />
            <TextInput
              label={t("Process.Properties.Email")}
              id={`${fieldPrefix}.email`}
              name={`${fieldPrefix}.email`}
              placeholder={t("Process.Applicants.Placeholders.Email")}
              value={applicant.email}
              action={handleChange}
              required={true}
              error={getError("email")}
              disabled={disabled}
            />
          </div>
          <div className="w-1/3">
            <TextInput
              label={t("Process.Properties.PhoneNumber")}
              id={`${fieldPrefix}.phoneNumber`}
              name={`${fieldPrefix}.phoneNumber`}
              placeholder={t("Process.Applicants.Placeholders.PhoneNumber")}
              value={applicant.phoneNumber}
              action={handleChange}
              required={false}
              error={getError("phoneNumber")}
              disabled={disabled}
            />
          </div>
          <div className="w-1/3">
            {offices && offices.length > 0 && (
              <SelectBox
                id={`${fieldPrefix}.officeId`}
                label={t("Process.Properties.OfficeId")}
                options={offices}
                required={true}
                error={getError("officeId")}
                action={handleChange}
                selected={selectedOffice()}
                disabled={disabled}
              />
            )}
          </div>
        </div>
        <div className="flex mb-4 gap-x-2">
          <div className="w-1/3">
            <TextInput
              label={t("Process.Properties.FirstName")}
              id={`${fieldPrefix}.firstName`}
              name={`${fieldPrefix}.firstName`}
              placeholder={t("Process.Applicants.Placeholders.FirstName")}
              value={applicant.firstName}
              action={handleChange}
              required={true}
              error={getError("firstName")}
              disabled={disabled}
            />
          </div>
          <div className="w-1/3">
            <TextInput
              label={t("Process.Properties.LastName")}
              id={`${fieldPrefix}.lastName`}
              name={`${fieldPrefix}.lastName`}
              placeholder={t("Process.Applicants.Placeholders.LastName")}
              value={applicant.lastName}
              action={handleChange}
              required={true}
              error={getError("lastName")}
              disabled={disabled}
            />
          </div>
          <div className="w-1/3">
            <SelectBox
              id={`${fieldPrefix}.languageCode`}
              label={t("Process.Properties.Language")}
              selectedOption={applicant.languageCode}
              options={locales}
              required={true}
              error={getError("languageCode")}
              action={handleChange}
              selected={selectedLanguage()}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
      <div>
        <Booking
          applicant={applicant}
          formData={formData}
          title={t("Process.Properties.InterviewDate")}
          modalTitle={t("BookingSelector.InterviewTitle")}
          label={getBookingLabel(
            applicant.individualInterviewDate,
            applicant.individualInterviewTime,
            formData.individualInterviews[0]?.duration
          )}
          date={applicant.individualInterviewDate}
          time={applicant.individualInterviewTime}
          type={formData.individualInterviews[0]?.interviewType}
          duration={formData.individualInterviews[0]?.duration}
          slotType={"individual"}
          handleBookingChange={handleBookingChange}
          disabled={disabled}
        />

        <Booking
          applicant={applicant}
          formData={formData}
          title={t("Process.Properties.GroupInterviewDate")}
          modalTitle={t("BookingSelector.GroupInterviewTitle")}
          label={getBookingLabel(
            applicant.groupSimulationDate,
            applicant.groupSimulationTime,
            60
          )}
          date={applicant.groupSimulationDate}
          time={applicant.groupSimulationTime}
          type={formData.groupSimulations[0]?.interviewType}
          duration={60}
          slotType={"group"}
          handleBookingChange={handleBookingChange}
          disabled={disabled}
        />

        <div className="flex gap-x-2 p-4">
          <div className="w-1/2">
            <DateTime
              dateFieldId={`${fieldPrefix}.individualSimulationDate`}
              timeFieldId={`${fieldPrefix}.individualSimulationTime`}
              dateValue={applicant.individualSimulationDate}
              timeValue={applicant.individualSimulationTime}
              dateError={getError("individualSimulationDate")}
              timeError={getError("individualSimulationTime")}
              fieldPrefix={fieldPrefix}
              label={t("Process.Properties.IndividualSimulationDate")}
              action={handleChange}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
      <div className="p-4">
        <div className="flex gap-x-2 text-sm">
          {isBlank(applicant.invitationSent) ? (
              <Checkbox
                  id={`${fieldPrefix}.invitationEnabled`}
                  name={`${fieldPrefix}.invitationEnabled`}
                  label={t("Process.Applicants.InvitationMail.SendInvitationMail")}
                  changeAction={handleCheckboxChange}
                  isChecked={applicant.invitationEnabled}
                  disabled={disabled}
              />
          ) : (
            <>
              <div className="flex gap-x-1.5">
                <CheckIcon
                  className="h-5 w-5 text-green-500"
                  aria-hidden="true"
                />
                <span>
                  {t("Process.Applicants.InvitationMail.InvitationSent")}
                </span>
                <span>{formatAsLocalDateTime(applicant.invitationSent)}</span>
              </div>
              <button
                className={"text-sm " + resendInviteButtonClassName}
                onClick={handleResendInvitationMail}
                disabled={disabled || isResending}>
                  <div>
                  {t("Process.Applicants.InvitationMail.ResendMessage")}
                  {isResending && (
                    <span className="ml-1">
                        <LoadingIndicator type="small" />
                    </span>
                  )}
                  </div>
                </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
